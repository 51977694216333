import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { APIService } from "./../api/api.service";
import { NotifierService } from "./../_services/notifier.service";

import SearchData from "../data/searchData";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { DashService } from "./dash.service";
import { LoginComponent } from "../login/login.component";

declare var $: any;
declare var window: any;

export let internetActive: boolean;

export let regions: any = {
  enabled: [],
  disabled: [],
};

export let cftrequired: any = {
  "tell-me": {
    cft: moment("09-05-2023", "DD-MM-YYYY").valueOf(),
    link: "/dash/tell-me",
  },
};

export let emsIntegration: any = [
  "/dash/reports/view?reportType=inventory",
  "/dash/site24x7/monitors",
];

export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

@Component({
  selector: "app-dash",
  templateUrl: "./dash.component.html",
  styleUrls: ["./dash.component.css"],
})
export class DashComponent implements OnInit {
  userId = localStorage.getItem("eId");
  username = localStorage.getItem("un");
  cbsAccess: boolean = false;
  started: boolean = false;
  showITAS: boolean = false;
  masterEmailId: string = localStorage.getItem("masterId");
  currentPageLink: string;
  hideAccountTab: boolean = true;
  subType: string = localStorage.getItem("subType") || "freetrial";
  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  productType: string = localStorage.getItem("productType") || null;
  loading: any = {
    jobs: false,
  };
  jobs: any = [];
  accId: string;
  eId: string;
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  monthYear: string = null;
  totalDiscount: any;
  discountPercentage: any;
  checkCount = 0;
  checkInterval = 10000;
  stars: any = [1, 2, 3];
  jobCurrentStatus = "";
  filter: any = {
    checks: "ALL",
    findings: "ALL",
  };
  checks: any = [];
  checksAcc: any = [];
  dateRange: any = "";

  progresspercentage: number;

  plans: any = {
    standard: "Standard",
    advanced: "Advanced",
    freetrial: "Free Trial",
  };

  validations: any = [
    {
      router: "/dash/accounts/aws",
      condition: () => {
        return (
          this.urlPrefix == "client" &&
          this.customerType == "mp" &&
          this.ut == "admin" &&
          !this.isUserAdmin
        );
      },
      title: "AWS",
    },
    {
      router: "/dash/wafr",
      condition: () => {
        return (
          this.checkIfAvaForUser("Compliance") &&
          this.productType != "WAFR" &&
          this.selectedCloudPartner == "aws"
        );
      },
      title: "WAFR",
    },
    {
      router: "/dash/ticket/create",
      condition: () => {
        return (
          (this.checkIfAvaForUser("cloudSupport") ||
            this.checkIfAvaForUser("Support")) &&
          this.writeAccess
        );
      },
      title: "Create Ticket",
    },
    {
      router: "/dash/ticket/list",
      condition: () => {
        return (
          this.checkIfAvaForUser("cloudSupport") ||
          this.checkIfAvaForUser("Support")
        );
      },
      title: "View Tickets",
    },
    {
      router: "/dash/knowledgebase",
      condition: () => {
        return (
          (this.checkIfAvaForUser("cloudSupport") ||
            this.checkIfAvaForUser("Support")) &&
          this.urlPrefix == "admin"
        );
      },
      title: "Knowledge Base",
    },
    { router: "/dash/home", condition: "", title: "Global" },
    {
      router: "/dash/compliance/dash",
      condition: () => {
        return (
          this.checkIfAvaForUser("Governance") ||
          this.checkIfAvaForUser("Compliance")
        );
      },
      title: "Governance",
    },
    {
      router: "/dash/operations/tags",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "EC2",
    },
    {
      router: "/dash/operations/rds",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "RDS",
    },
    {
      router: "/dash/operations/ebs",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "EBS",
    },
    {
      router: "/dash/operations/acm",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "ACM",
    },
    {
      router: "/dash/compliance/tags",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "Tags",
    },
    {
      router: "/dash/compliance/cloudtrail",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Cloud Trail",
    },
    {
      router: "/dash/s3/operations",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "S3",
    },
    { router: "/dash/compliance/dash", condition: "", title: "Dashboard" },
    {
      router: "/dash/compliance/findings?audit_type=ec2",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "EC2",
    },
    {
      router: "/dash/compliance/findings?audit_type=elb",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "ELB",
    },
    {
      router: "/dash/compliance/findings?audit_type=s3",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "S3",
    },
    {
      router: "/dash/compliance/s3/age_report",
      condition: () => {
        return this.checkIfAvaForUser("s3") && this.writeAccess;
      },
      title: "S3 Age Report",
    },
    {
      router: "/dash/compliance/findings?audit_type=sg",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Security Groups",
    },
    {
      router: "/dash/compliance/findings?audit_type=apigateway",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "API Gateway",
    },
    {
      router: "/dash/compliance/findings?audit_type=lambda",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Lambda",
    },
    {
      router: "/dash/compliance/findings?audit_type=ses",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "SES",
    },
    {
      router: "/dash/compliance/findings?audit_type=sns",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "SNS",
    },
    {
      router: "/dash/compliance/findings?audit_type=kube",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "EKS",
    },
    {
      router: "/dash/compliance/findings?audit_type=dynamo",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "DynamoDB",
    },
    {
      router: "/dash/compliance/findings?audit_type=es",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Elastic Search",
    },
    {
      router: "/dash/compliance/findings?audit_type=rds",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "RDS",
    },
    {
      router: "/dash/compliance/findings?audit_type=cloudfront",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Cloudfront",
    },
    {
      router: "/dash/compliance/ebs",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "EBS",
    },
    {
      router: "/dash/compliance/findings?audit_type=redshift",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Redshift",
    },
    {
      router: "/dash/compliance/findings?audit_type=cloudtrail",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Cloudtrail Compliance",
    },
    {
      router: "/dash/compliance/findings?audit_type=route53",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Route 53",
    },
    {
      router: "/dash/billing/billing-daily",
      condition: () => {
        return (
          this.checkIfAvaForUser("Cost Controls") ||
          this.checkIfAvaForUser("CostOptimization")
        );
      },
      title: "Daily Variance Report",
    },
    {
      router: "/dash/billing/billing-anomaly",
      condition: () => {
        return (
          this.checkIfAvaForUser("Cost Controls") ||
          this.checkIfAvaForUser("CostOptimization")
        );
      },
      title: "Cost Anomaly Report",
    },
    {
      router: "/dash/costopt/ec2",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "EC2",
    },
    {
      router: "/dash/costcontrols/ec2-ri",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "RI Recommendations",
    },
    {
      router: "/dash/cost-controls/reserved-instances",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "Reserved Instances",
    },
    {
      router: "/dash/costoptimization/ebs",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "EBS",
    },
    {
      router: "/dash/costopt/floating",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "AMIs and Snapshots",
    },
    {
      router: "/dash/s3/cost-controls",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "S3",
    },
    {
      router: "/dash/cost-controls/rds",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "RDS",
    },
    {
      router: "/dash/cost-controls/cloudwatch",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "Cloudwatch",
    },
    {
      router: "/dash/costopt/other",
      condition: () => {
        return (
          this.checkIfAvaForUser("CostOptimization") ||
          this.checkIfAvaForUser("Cost Controls")
        );
      },
      title: "Other",
    },
    {
      router: "/dash/compliance/ssb",
      condition: () => {
        return this.checkIfAvaForUser("Security");
      },
      title: "Security Baseline",
    },
    {
      router: "/dash/security/ftr",
      condition: () => {
        return this.checkIfAvaForUser("Security");
      },
      title: "FTR",
    },
    {
      router: "/dash/wafr",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "WAFR",
    },
    {
      router: "/dash/rbi-compliance",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "RBI Compliance",
    },
    {
      router: "/dash/compliance/findings?audit_type=iam",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "IAM",
    },
    {
      router: "/dash/compliance/iam/users",
      condition: () => {
        return this.checkIfAvaForUser("Security") && this.writeAccess;
      },
      title: "IAM Users",
    },
    {
      router: "/dash/compliance/iam/age_report",
      condition: () => {
        return this.checkIfAvaForUser("Security") && this.writeAccess;
      },
      title: "IAM Access Key Age Report",
    },
    {
      router: "/dash/operations/sg",
      condition: () => {
        return this.checkIfAvaForUser("Security");
      },
      title: "Security Groups",
    },
    {
      router: "/dash/compliance/s3",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "S3",
    },
    {
      router: "/dash/compliance/findings?audit_type=cft",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Cloud Formation",
    },
    {
      router: "/dash/compliance/findings?audit_type=kms",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "KMS",
    },
    {
      router: "/dash/compliance/patch",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Patch Report",
    },
    {
      router: "/dash/compliance/securityhub",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Security Hub",
    },
    {
      router: "/dash/compliance/advisor",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Trusted Advisor",
    },
    {
      router: "/dash/compliance/inspector",
      condition: () => {
        return this.checkIfAvaForUser("Security");
      },
      title: "Inspector",
    },
    {
      router: "/dash/compliance/config",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Config",
    },
    {
      router: "/dash/tell-me",
      condition: () => {
        return this.checkIfAvaForUser("Compliance");
      },
      title: "Tell Me",
    },
    {
      router: "/dash/compliance/findings?audit_type=cloudwatch",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Cloudwatch",
    },
    {
      router: "/dash/monitoring",
      condition: () => {
        return (
          this.checkIfAvaForUser("Monitoring") && this.customerType != "mp"
        );
      },
      title: "EMS Dashboard",
    },
    {
      router: "/dash/billing/dashboard",
      condition: () => {
        return this.checkIfAvaForUser("Billing");
      },
      title: "Dashboard",
    },
    {
      router: "/dash/billing",
      condition: () => {
        return this.checkIfAvaForUser("Billing");
      },
      title: "Monthly Bill",
    },
    {
      router: "/dash/billing/ri",
      condition: () => {
        return this.checkIfAvaForUser("Billing") && this.urlPrefix == "admin";
      },
      title: "RI Order Report",
    },
    {
      router: "/dash/sla/dashboard",
      condition: () => {
        return this.checkIfAvaForUser("SLAReport");
      },
      title: "SLA Dashboard",
    },
    {
      router: "/dash/sla/createdvsresolved",
      condition: () => {
        return this.checkIfAvaForUser("SLAReport");
      },
      title: "Created vs Resolved",
    },
    {
      router: "/dash/sla/firstresponsesla",
      condition: () => {
        return this.checkIfAvaForUser("SLAReport");
      },
      title: "First Response SLA",
    },
    {
      router: "/dash/sla/resolutionsla",
      condition: () => {
        return this.checkIfAvaForUser("SLAReport");
      },
      title: "Resolution SLA",
    },
    {
      router: "/dash/reports/view",
      condition: () => {
        return this.checkIfAvaForUser("Reports");
      },
      title: "View and Download Reports",
    },
    {
      router: "/dash/reports/enable",
      condition: () => {
        return this.checkIfAvaForUser("Reports") && this.writeAccess;
      },
      title: "Automated Reports",
    },
    {
      router: "/dash/beta/automation/scheduler",
      condition: () => {
        return this.checkIfAvaForUser("Automation");
      },
      title: "Scheduler",
    },
    {
      router: "/dash/bom/upload",
      condition: () => {
        return this.checkIfAvaForUser("BOM") && this.writeAccess;
      },
      title: "Upload BOM",
    },
    {
      router: "/dash/bom/view",
      condition: () => {
        return this.checkIfAvaForUser("BOM");
      },
      title: "View BOM Details",
    },
    {
      router: "/dash/bom/deltareport",
      condition: () => {
        return this.checkIfAvaForUser("BOM");
      },
      title: "Delta Report",
    },
    {
      router: "/dash/site24x7/monitors",
      condition: () => {
        return true;
      },
      title: "Monitors",
    },
    {
      router: "/dash/beta/costoptimization/tags",
      condition: () => {
        return this.checkIfAvaForUser("Beta") && this.betaAccess;
      },
      title: "Tags Cost Controls",
    },
    {
      router: "/dash/beta/ticket/create",
      condition: () => {
        return (
          this.checkIfAvaForUser("cloudSupport:ticket") && this.writeAccess
        );
      },
      title: "Create Ticket",
    },
    {
      router: "/dash/beta/iam/groups",
      condition: () => {
        return this.checkIfAvaForUser("iam") && this.writeAccess;
      },
      title: "IAM Groups",
    },
    {
      router: "/dash/azure/vms",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "VMs",
    },
    {
      router: "/dash/azure/disks",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "Disks",
    },
    {
      router: "/dash/azure/networks",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "Networks",
    },
    {
      router: "/dash/azure/databases",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "Databases",
    },
    {
      router: "/dash/azure/functions",
      condition: () => {
        return this.checkIfAvaForUser("Operations");
      },
      title: "Functions",
    },
    {
      router: "/dash/compliance/findings?audit_type=sql_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "SQL",
    },
    {
      router: "/dash/compliance/findings?audit_type=vm_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "VM",
    },
    {
      router: "/dash/compliance/findings?audit_type=app_service_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "App Service",
    },
    {
      router: "/dash/compliance/findings?audit_type=network_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Network",
    },
    {
      router: "/dash/compliance/findings?audit_type=key_vault_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Key Vault",
    },
    {
      router: "/dash/compliance/findings?audit_type=monitor_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Monitor",
    },
    {
      router: "/dash/compliance/findings?audit_type=log_alert_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Log Alert",
    },
    {
      router: "/dash/compliance/findings?audit_type=security_center_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Security Center",
    },
    {
      router: "/dash/compliance/findings?audit_type=storage_audit",
      condition: () => {
        return this.checkIfAvaForUser("Governance");
      },
      title: "Storage",
    },
    {
      router: "/dash/settings/users/view",
      condition: () => {
        return (
          (this.checkIfAvaForUser("Settings") && this.ut == "admin") ||
          this.isUserAdmin
        );
      },
      title: "Users",
    },
    {
      router: "/dash/settings/policies/view",
      condition: () => {
        return (
          this.checkIfAvaForUser("Settings") &&
          this.urlPrefix == "client" &&
          (this.ut == "admin" || this.isUserAdmin)
        );
      },
      title: "Policies",
    },
    {
      router: "/dash/settings/audittrail",
      condition: () => {
        return this.checkIfAvaForUser("Settings");
      },
      title: "Audit Trail",
    },
    {
      router: "/dash/settings/profile",
      condition: () => {
        return this.checkIfAvaForUser("Settings");
      },
      title: "User Management",
    },
    {
      router: "/dash/feedback",
      condition: () => {
        return this.checkIfAvaForUser("Feedback");
      },
      title: "Feedback",
    },
  ];

  whatsNew: any = [
    {
      name: "Tell Me",
      url: "/dash/tell-me",
      permission: () => {
        return (
          this.checkIfAvaForUser("Monitoring") &&
          moment().valueOf() <
            moment("22-06-2023", "DD-MM-YYYY").endOf("day").valueOf()
        );
      },
      validTill: "22-06-2023",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Tell+Me+Feature%404x.png",
    },
    {
      name: "EC2 Cost Controls",
      url: "/dash/costopt/ec2",
      permission: () => {
        return (
          (this.checkIfAvaForUser("Cost Controls") ||
            this.checkIfAvaForUser("CostOptimization") ||
            this.checkIfAvaForUser("Cost Controls")) &&
          moment().valueOf() <
            moment("22-06-2023", "DD-MM-YYYY").endOf("day").valueOf()
        );
      },
      validTill: "22-06-2023",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png",
    },
  ];

  totalPeriod: number = localStorage.getItem("totalPeriod")
    ? Number(localStorage.getItem("totalPeriod"))
    : 0;

  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  urlPre: any = localStorage.getItem("role") == "Admin" ? "admin" : "market";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  agl: any;

  expanded: any = {
    site24x7: false,
    infra: false,
    security: false,
    maintenance: false,
    expenditure: false,
    optimization: false,
  };

  BOMAccess: boolean = this.urlPrefix === "admin";
  betaAccess: boolean = false;
  accessType: string = localStorage.getItem("acT");
  access: string = localStorage.getItem("accessType");
  customerType: string = localStorage.getItem("customerType");

  accountGroups: any = {
    list: [],
    selected: "",
  };
  clientNames: any = {
    list: [],
    selected: "",
  };
  accountIds: any = {
    list: [],
    selected: "",
  };
  searchValue: string = "";
  recentSearchs: any = [];
  regions: any = {
    enabled: [],
    disabled: [],
  };

  expiresIn: any = localStorage.getItem("expiresIn");
  //regions = [
  //  { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)ap-south-1' },
  //  { id: 'us-east-1', name: 'US East (N. Virginia)us-east-1' },
  //  { id: 'us-east-2', name: 'US East (Ohio)us-east-2' },
  //  { id: 'us-west-1', name: 'US West (N. California)us-west-1' },
  //  { id: 'us-west-2', name: 'US West (Oregon)us-west-2' },
  //  { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)ap-northeast-3' },
  //  { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)ap-northeast-2' },
  //  { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)ap-southeast-1' },
  //  { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)ap-southeast-2' },
  //  { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)ap-northeast-1' },
  //  { id: 'ca-central-1', name: 'Canada (Central)ca-central-1' },
  //  { id: 'eu-central-1', name: 'Europe (Frankfurt)eu-central-1' },
  //  { id: 'eu-west-1', name: 'Europe (Ireland)eu-west-1' },
  //  { id: 'eu-west-2', name: 'Europe (London)eu-west-2' },
  //  { id: 'eu-west-3', name: 'Europe (Paris)eu-west-3' },
  //  { id: 'eu-north-1', name: 'Europe (Stockholm)eu-north-1' },
  //  { id: 'me-south-1', name: 'Middle East (Bahrain)me-south-1' },
  //  { id: 'sa-east-1', name: 'South America (São Paulo)sa-east-1' },
  //  { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)ap-east-1' },
  //  { id: 'af-south-1', name: 'Africa (Cape Town)af-south-1' },
  //  { id: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)ap-southeast-3' },
  //  { id: 'eu-south-1', name: 'Europe (Milan)eu-south-1' },
  //];

  selectedRegion = "Asia Pacific (Mumbai) ap-south-1";
  selectedRegionId = "ap-south-1";

  accountIdSearchTerm: any;
  clientNameSearchTerm: any;
  accountGroupSearchTerm: any;
  accessList: any = localStorage.getItem("menuList");
  showSearchResult: boolean = false;
  searchData: any = SearchData;
  searchResults: any;
  currentRoute: string = null;
  showcontent: boolean = false;
  acc_cftstatus: any;

  notification: any[] = [];
  noticount: any = 0;
  viewNotificationTrigger = false;
  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService,
    private dashService: DashService
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
    if (this.whitelabelData["pd"]) {
      localStorage.setItem(
        "whitelabelData",
        JSON.stringify(this.whitelabelData)
      );
      this.whitelabelData = this.route.snapshot.data.data;

      if (this.whitelabelData["pd"]["logoLight"]) {
        this.changeFavicon(this.whitelabelData["pd"]["logoLight"]);
      }
    }
    if (this.accessList) {
      let temp: any = [];
      try {
        let accessList: any = JSON.parse(this.accessList);
        temp = [...Object.keys(accessList)];
        for (const key in accessList) {
          if (accessList.hasOwnProperty(key)) {
            const values = accessList[key].map(
              (value: any) => `${key.trim()}:${value.trim()}`
            );
            temp.push(...values);
          }
        }
        this.accessList = temp;
      } catch (error) {
        this.accessList = [];
      }
    }
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
        if (localStorage.getItem("accountStatus") == "Inactive") {
          if (e.url.indexOf("/dash/renewal") < 0) {
            this.showExpired = true;
            this.notifier.loading(false);
          } else {
            this.showExpired = false;
          }
        } else {
          this.showExpired = false;
        }
        this.currentPageLink = e.url;

        this.checkForValidation();

        this.checkForMandatory();
      }
    });
  }

  checkForValidation() {
    return true;
    let check: any = this.validations.find((validate: any) => {
      return this.currentPageLink.endsWith(validate["router"]);
    });

    if (check) {
      if (check.condition == "" || check.condition()) {
        return true;
      } else {
        console.log(check.condition(), check, this.currentPageLink);

        this.router.navigate(["/login"]);
        return true;
      }
    } else {
      return true;
    }
  }

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById("favicon");
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.type = "image/png";
      newFavicon.href = newFaviconURL;
      newFavicon.id = "favicon";
      document.head.appendChild(newFavicon);
    }
  }

  showExpired: boolean = false;

  dashServiveMessage: any;

  async ngOnInit() {
    this.notifier.loading(true);
    this.notifier.loading(true);
    this.accId = localStorage.getItem("accountId");
    this.eId = localStorage.getItem("eId");
    this.dateRange = moment().startOf("month").format("MM-YYYY");
    $("#datetimepicker").val(moment().startOf("month").format("MM-YYYY"));
    this.dashServiveMessage = this.dashService.currentMessage.subscribe(
      (msg: any) => {
        if (!msg) {
          return;
        }

        msg = JSON.parse(msg);

        if (msg["action"] == "refresh_accounts") {
          localStorage.removeItem("accountListNew");
          if (this.urlPrefix == "admin") {
            this.loadAccountList(localStorage.getItem("selectedClientList"));
          } else {
            this.loadAccounts();
          }
        } else if (msg["action"] == "green_game") {
          console.log("Testing");
          this.greengame(msg["data"]["date"]);

          if (msg["data"]["type"] == "show_loader") {
            this.greengame(msg["data"]["date"]);
          }
          // if (msg["data"]["date"] == Date) {
          //   this.greengame((msg["data"]["date"]));
          // }
        }
      }
    );
    // window.addEventListener("online", () => {
    //   alert("Internet is active");
    //   internetActive = true;
    // });
    // window.addEventListener("offline", () => {
    //   alert("Internet is down");
    //   internetActive = false;
    // });
    this.regions = regions;

    if (localStorage.getItem("accountStatus") == "Inactive") {
      return false;
    }
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }

    if (this.urlPrefix == "admin") {
      this.checkForCBS();
    }

    if (this.customerType != "mp") {
      this.started = true;
    }
    this.checkForBetaAccess();

    await this.loadHeader();
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    return true;
  }

  totalWhatsNew() {
    return this.whatsNew.filter((new_: any) => {
      return new_["permission"]();
    });
  }

  mandatory: boolean = false;

  checkForMandatory() {
    let check: any = Object.keys(cftrequired).find((feature: string) => {
      return (
        window.location.pathname.indexOf(cftrequired[feature]["link"]) > -1
      );
    });
    this.mandatory = check ? true : false;
  }

  checkForEMS() {
    return (
      emsIntegration.includes(this.currentPageLink) &&
      !localStorage.getItem("emsConfig") &&
      this.getCurrentAccount() &&
      !this.getCurrentAccount()["site_247_project_id"]
    );
  }

  createBUCheck(event: any) {
    if (event) {
      localStorage.removeItem("accountListNew");
      if (this.urlPrefix == "admin") {
        this.loadAccountList(localStorage.getItem("selectedClientList"));
      } else {
        this.loadAccounts();
      }
    }

    this.enableBU = false;
  }

  link: string = null;

  async loadCFTLink() {
    let apiURL: string = `https://prod.api.swayam.cloud/v3/cft`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiServer.postDataPromis(apiURL, {}, header);

    if (result.status == "1" || result.s == "1") {
      this.link = result["template_url"].replace(
        "RootAdminEmailIdValue",
        this.userId || localStorage.getItem("eId")
      );
    }
  }

  async checkForBetaAccess() {
    let data = {
      action: "check_user",
      uid: localStorage.getItem("eId"),
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/checkdevuser`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      if (result.msg == "Y") {
        this.betaAccess = true;
      } else {
        this.betaAccess = false;
      }
    }
    //if (this.CBSPortal && !this.cbsAccess) {
    //  this.logout();
    //}
  }

  async checkForCBS() {
    let data = {
      emailID: localStorage.getItem("eId"),
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchcbsuserstatus`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      if (result.enableCBS) {
        this.cbsAccess = true;
      }
    }
  }

  async checkForCFT(account_id: any, select: boolean = true) {
    return new Promise(async (resolve: any, reject: any) => {
      if (account_id["cft_status"] == undefined) {
        account_id["cft_status"] = "loading";
      }
      let data = {
        action: "validate_access",
        iam_role_arn: `arn:aws:iam::${account_id["accountId"]}:role/msopsstsrole`,
        session_name: "Swayam@AssumeRoleValidate",
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/accounts/validate`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
        account_id["cft_status"] = true;
        account_id["cft_latest_version"] = moment(
          result["LatestStackVersion"],
          "DD-MM-YYYY"
        ).valueOf();
        account_id["cft_latest_version_raw"] = result["LatestStackVersion"];
        // result["StackVersion"] = "1.0.1";
        try {
          if (moment(result["StackVersion"], "DD-MM-YYYY", true).isValid()) {
            account_id["cft_current_version"] = moment(
              result["StackVersion"],
              "DD-MM-YYYY"
            ).valueOf();
          } else {
            account_id["cft_current_version"] = moment(
              "01-01-2023",
              "DD-MM-YYYY"
            ).valueOf();
          }
        } catch {
          account_id["cft_current_version"] = moment(
            "01-01-2023",
            "DD-MM-YYYY"
          ).valueOf();
        }
        if (result["CloudShell"]) {
          result["CloudShell"]["Command"] = result["CloudShell"][
            "Command"
          ].replace("RootAdminEmailIdValue", this.userId);
        }
        account_id["cft_latest_url"] = result["CloudShell"];
        account_id["cft_deployed_region"] = result["StackRegion"];
        let currentAccount: any = this.getCurrentAccount();
        if (currentAccount) {
          if (currentAccount["accountId"] == account_id["accountId"]) {
            this.started = true;
          }
        }
      } else {
        account_id["cft_status"] = false;
        account_id["cft_latest_url"] = this.link;
        if (
          this.customerType == "mp" &&
          localStorage.getItem("accountId") == account_id["accountId"] &&
          this.selectedCloudPartner != "azure"
        ) {
          this.started = false;
        }
      }

      if (
        localStorage.getItem("accountId") == account_id["accountId"] &&
        localStorage.getItem("role") == "Client" &&
        select
      ) {
        this.setAccountList(account_id["accountId"]);
      }
      resolve(result);
    });
  }

  async fetchAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: "fetchAllAccountGroups2",
        clientId: localStorage.getItem("eId"),
        userId: localStorage.getItem("eId"),
        ut: localStorage.getItem("ut"),
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == "1") {
        result.agl = result.agl.sort(function (a: any, b: any) {
          if (a.groupName < b.groupName) {
            return -1;
          }
          if (a.groupName > b.groupName) {
            return 1;
          }
          return 0;
        });
        if (result.agl) {
          localStorage.setItem("accountGroups", JSON.stringify(result.agl));
          await this.loadAccountGroups();
          resolve({ s: "1", message: "Account Groups Fetched" });
        } else {
          reject({ s: "0", message: "Account Groups Not Found" });
        }
      }
      resolve(result);
    });
  }

  async loadAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      const accountGroups = localStorage.getItem("accountGroups");
      try {
        const data = JSON.parse(accountGroups);
        if (data.length > 0) {
          this.accountGroups.list = data.sort(function (a: any, b: any) {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });
          const prevAccountGroup = localStorage.getItem("selectedAccountGroup");
          if (prevAccountGroup) {
            await this.setAccountGroup(prevAccountGroup);
          } else {
            await this.setAccountGroup(data[0].groupName);
          }
          resolve({ s: "1", message: "ACCOUNT GROUPS LOADED" });
        } else {
          await this.fetchAccountGroups();
        }
      } catch (err) {
        await this.fetchAccountGroups();
      }
    });
  }

  async setAccountGroup(groupName: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.accountGroups.selected = groupName;
    const prevAccountGroup = localStorage.getItem("selectedAccountGroup");
    if (prevAccountGroup) {
      if (prevAccountGroup != groupName) {
        await this.fetchClientList(groupName);
      } else {
        this.loadClientList(groupName);
      }
    } else {
      await this.fetchClientList(groupName);
    }
    localStorage.setItem("selectedAccountGroup", groupName);
  }

  async fetchClientList(groupName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: "fetchSwayamClientList",
        clientId: localStorage.getItem("eId"),
        userId: localStorage.getItem("eId"),
        groupName: groupName,
        ut: "admin",
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == "1") {
        if (result.agl) {
          result.agl[0]["accountGroup"]["accountGroupList"][
            "accountGroupDetails"
          ] = result.agl[0]["accountGroup"]["accountGroupList"][
            "accountGroupDetails"
          ].sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          localStorage.setItem(
            "clientList",
            JSON.stringify(
              result.agl[0]["accountGroup"]["accountGroupList"][
                "accountGroupDetails"
              ]
            )
          );
          await this.loadClientList(groupName);
          resolve({ s: "1", message: "Client List Fetched" });
        } else {
          reject({ s: "0", message: "Client List Not Found" });
        }
      }
      resolve(result);
    });
  }

  async loadClientList(groupName: any) {
    return new Promise(async (resolve, reject) => {
      const clientList = localStorage.getItem("clientList");
      try {
        const data = JSON.parse(clientList);
        if (data.length > 0) {
          this.clientNames.list = data.map((client: any) => {
            client["cmpName"] = client["cmpName"].trim();
            return client;
          });
          this.clientNames.list = data.sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          const prevClientList = localStorage.getItem("selectedClientList");
          if (
            prevClientList &&
            localStorage.getItem("selectedAccountGroup") == groupName
          ) {
            await this.setClientList(prevClientList);
          } else {
            await this.setClientList(data[0]["emailId"]);
          }
          resolve({ s: "1", message: "CLIENT LIST LOADED" });
        } else {
          await this.fetchClientList(groupName);
        }
      } catch (err) {
        await this.fetchClientList(groupName);
      }
    });
  }

  async setClientList(clientName: any) {
    $("#wait").show();
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.clientNames.selected = this.clientNames.list.find((client: any) => {
      return client.emailId == clientName;
    })["cmpName"];
    const prevClientList = localStorage.getItem("selectedClientList");
    if (prevClientList) {
      if (prevClientList != clientName) {
        await this.fetchAccountList(clientName);
      } else {
        this.loadAccountList(clientName);
      }
    } else {
      await this.fetchAccountList(clientName);
    }
    localStorage.setItem("selectedClientList", clientName);
    this.loadMonitorGroups();
    return true;
  }

  async fetchAzureSubscriptions(clientName: string) {
    let data: any = {
      action: "list_subscriptions",
      user_id:
        this.urlPrefix == "admin" &&
        (this.accountGroups["selected"] == "DevelopmentTestGroup" ||
          this.accountGroups["selected"] == "MinfyDevTeam")
          ? "rakeshkumar.t@minfytech.com"
          : this.userId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      result.subscriptions = result.subscriptions.map((subscription: any) => {
        return {
          accountId: subscription["subscription_id"],
          accountName: subscription["subscription_name"],
          cp: "azure",
        };
      });
      this.accountIds.list = [...this.accountIds.list, ...result.subscriptions];
    } else {
      localStorage.removeItem("azureSubscriptions");
    }
  }

  async fetchAccountList(clientName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      this.accountIds.list = [];
      let data = { a: "fetchAccounts", eId: clientName };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/awsaccounts`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == "1") {
        if (result.config) {
          localStorage.setItem("emsConfig", JSON.stringify(result.config));
          this.loadMonitorGroups();
        } else {
          if (localStorage.getItem("emsConfig")) {
            localStorage.removeItem("emsConfig");
          }
        }
        if (result.accounts) {
          result.accounts = result.accounts
            .map((account: any) => {
              account["cp"] = "aws";
              return account;
            })
            .sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          localStorage.setItem("accountId", result.accounts[0]["accountId"]);
          // await this.fetchAzureSubscriptions(clientName);
          localStorage.setItem(
            "accountListNew",
            JSON.stringify([...this.accountIds.list, ...result.accounts])
          );
          await this.loadAccountList(clientName);
          resolve({ s: "1", message: "Account Groups Fetched" });
        } else {
          // await this.fetchAzureSubscriptions(clientName);
          reject({ s: "0", message: "Account Groups Not Found" });
        }
      }

      if (this.accountIds.list.length == 0) {
        this.loadRegions();
      }
      resolve(result);
    });
  }

  async loadAccountList(clientList: any) {
    return new Promise(async (resolve, reject) => {
      const accountList = localStorage.getItem("accountListNew");
      if (!accountList) {
        this.fetchAccountList(clientList);
        resolve({ s: "1", message: "ACCOUNT LIST LOADED" });
        return;
      }
      try {
        const data = JSON.parse(accountList);
        if (data.length > 0) {
          this.accountIds.list = data.sort(function (a: any, b: any) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          const prevAccountList = localStorage.getItem("accountId");
          if (
            prevAccountList &&
            localStorage.getItem("selectedClientList") == clientList
          ) {
            localStorage.setItem("accountId", prevAccountList);
          } else {
            localStorage.setItem("accountId", data[0]["accountId"]);
          }
          let promises: any = [];
          this.accountIds.list.forEach((account: any) => {
            if (account["cp"] != "azure") {
              promises.push(this.checkForCFT(account));
            } else {
              account["cft_status"] = true;
            }
          });
          Promise.all(promises).then(async (values: any) => {
            localStorage.setItem(
              "accountIdData",
              JSON.stringify(this.accountIds)
            );
            if (
              prevAccountList &&
              localStorage.getItem("selectedClientList") == clientList
            ) {
              await this.setAccountList(prevAccountList);
            } else {
              await this.setAccountList(data[0]["accountId"]);
            }
          });
          resolve({ s: "1", message: "ACCOUNT LIST LOADED" });
        } else {
          await this.fetchClientList(this.accountGroups.selected);
        }
      } catch (err) {
        console.log("LOADING ACCOUNT LIST", err);
        await this.fetchClientList(this.accountGroups.selected);
      }
    });
  }

  async setAccountList(accountId: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    const accountList = localStorage.getItem("accountListNew");
    try {
      const data = JSON.parse(accountList);
      let account = data.find((account: any) => {
        return account.accountId == accountId;
      });

      if (!account) {
        account = this.accountIds.list.find((account_: any) => {
          return account_.accountId == accountId;
        });
      }

      this.accountIds.selected = account.accountName + `(${account.accountId})`;
      if (
        !account["cft_status"] &&
        this.customerType == "mp" &&
        this.selectedCloudPartner != "azure"
      ) {
        this.started = false;
        this.router.navigate(["/dash/accounts/aws"]);
      } else {
        this.started = true;
      }
      localStorage.setItem("accountId", account.accountId);
      if (account) {
        localStorage.setItem("swayam_jira_org_id", account.jira_org_id);
        localStorage.setItem("swayam_jira_org_name", account.jira_org_name);
        localStorage.setItem("swayam_jira_project_id", account.jira_project_id);
        localStorage.setItem(
          "swayam_jira_project_key",
          account.jira_project_key
        );
        localStorage.setItem(
          "swayam_jira_project_name",
          account.jira_project_name
        );
        localStorage.setItem(
          "swayam_site_247_project_id",
          account.site_247_project_id
        );
        localStorage.setItem(
          "site_247_project_name",
          account.site_247_project_name
        );
        localStorage.setItem("monitoringUrl", account.monitoringUrl);
      }
    } catch (err) {}

    let account: any = this.accountIds.list.find((account_: any) => {
      return account_.accountId == accountId;
    });

    // this.selectedCloudPartner = account["cp"] || "";

    if (
      !account["cft_status"] &&
      this.customerType == "mp" &&
      this.selectedCloudPartner != "azure"
    ) {
      this.started = false;
      this.router.navigate(["/dash/accounts/aws"]);
    } else {
      this.started = true;
    }

    this.selectAccountId(account);
    if (this.urlPrefix == "client") {
      this.loadMonitorGroups();
    }
    this.notifier.loading(false);
    return true;
  }

  //TODO : save on Change

  checkIfAvaForUser(routeName: string): boolean {

    routeName = routeName.trim();
    if (localStorage.getItem("role") === "Client") {
      if (
        this.accessList.length === 0 ||
        this.accessList.indexOf(routeName) > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async navigateTo(param: any) {
    // await this.setRecentSearch(param);
    this.searchValue = "";
    this.router.navigateByUrl(param.url);
  }

  getPercentage(val?: number) {
    if (!val) {
      val = 0;
    }
    var r = 12;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  width: any;
  currentAccountCFTStatus: boolean = null;
  checkingCFT: boolean = false;

  async loadHeader() {
    //for notification
    this.loadNotificationCount();
    setInterval(() => {
      this.loadNotificationCount();
    }, 3600000);

    this.loadCFTLink();
    this.notifier.loading(true);

    if (localStorage.getItem("role") == "Client") {
      let data = localStorage.getItem("accountIdData");
      var max = -219.99078369140625;
      let percent = (this.expiresIn / 15) * 100;
      this.width = ((100 - percent) / 100) * max;
      this.accountIds.list = [];
      // if (data) {
      //   this.accountIds = {
      //     selected: JSON.parse(data).selected,
      //     list: JSON.parse(data).list,
      //   };
      //   if (
      //     this.accountIds &&
      //     this.accountIds.list &&
      //     this.accountIds.list.length == 0
      //   ) {
      //    true;true;
      //   } else {
      //     this.started = false;
      //   }
      //   localStorage.setItem("accountId", this.accountIds.list[0].accountId);
      //   localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
      //   this.selectAccountId(
      //     JSON.parse(data).list.find((account: any) => {
      //       //return account.accountName == this.accountIds.selected;
      //       return this.accountIds.selected.indexOf(account.accountId) > -1;
      //     })
      //   );
      //   this.accountIds.list.forEach((account: any) => {
      //     this.checkForCFT(account);
      //   });
      //   return true;
      // } else {
      //   let data = {
      //     a: "fetch",
      //     eid: localStorage.getItem("eId"),
      //   };
      //   let header = {
      //     Authorization: localStorage.getItem("t"),
      //   };
      //   let apiUrl = `${APIService.API_ENDPOINTV3}/market/user/accounts`;

      //   let result = await this.apiServer.postDataPromis(apiUrl, data, header);
      //   if (result.s == "1" || result.status == "1") {
      //     this.accountIds.list = result.accounts;
      //     if (
      //       this.accountIds &&
      //       this.accountIds.list &&
      //       this.accountIds.list.length == 0
      //     ) {
      //      true;true;
      //     } else {
      //       this.started = false;
      //     }
      //     this.accountIds.list.forEach((account: any) => {
      //       this.checkForCFT(account);
      //     });
      //     this.selectAccountId(this.accountIds.list[0]);
      //   } else {
      //    true;true;
      //   }
      //   // this.notifier.loading(false);
      //   return true;
      // }
      this.loadAccounts();
      return false;
    }

    await this.loadAccountGroups();
    return true;
    let agl = localStorage.getItem("agl");
    if (agl) {
      console.log("agl", agl);
      this.agl = JSON.parse(agl);

      this.loadAGL();
      return true;
    }

    this.notifier.loading(true);
    let data: any = {
      action: "fetchAccountGroupsAndAccIDs",
      userId: this.userId,
      ut: this.ut,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
    console.log("FETCHING HEADER DATA", this.urlPrefix);
    if (this.urlPrefix == "admin") {
      // console.log('URL ADMIN:', this.urlPrefix);
      // apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
      console.log("I AM ADMIN");
      await this.loadAccountGroups();
      return true;
    } else {
      console.log("URL ADMIN:", this.urlPrefix);
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == "1" || result.status == "1") {
      this.notifier.loading(false);
      if (this.urlPrefix == "client") {
        this.accountIds = {
          selected: result.list[0]["otherdetail"][0].accountName,
          list: result.list[0]["otherdetail"],
        };
        localStorage.setItem("accountId", this.accountIds.list[0].accountId);
        localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
        this.selectAccountId(this.accountIds.list[0]);
        return true;
      }

      this.agl = result.agl;

      localStorage.setItem("agl", JSON.stringify(result.agl));

      //
      this.accountGroups = {
        selected: this.agl[0].groupName,
        list: [],
      };

      for (let i = 0; i < this.agl.length; i++) {
        this.accountGroups.list.push(this.agl[i]);
      }

      localStorage.setItem("selectedAccountGroup", this.accountGroups.selected);
      localStorage.setItem(
        "selectedAccountGroupData",
        JSON.stringify(this.accountGroups)
      );

      console.log(
        "this.accountGroups",
        JSON.stringify(
          this.accountGroups.list[0].accountGroup.accountGroupList
            .accountGroupDetails,
          null,
          2
        )
      );

      let value =
        this.accountGroups.list[0].accountGroup.accountGroupList
          .accountGroupDetails;
      this.clientNames = {
        selected: value[0].cmpName,
        list: value,
      };

      let clientName = this.clientNames.list[0];

      this.clientNames.selected = clientName.cmpName;

      localStorage.setItem("selectedClientName", this.clientNames.selected);
      localStorage.setItem(
        "selectedClientNameData",
        JSON.stringify(this.clientNames)
      );

      this.accountIds = {
        selected: clientName.accList[0].accountName,
        list: clientName.accList,
      };

      localStorage.setItem("selectedaccountIds", this.accountIds.selected);
      localStorage.setItem("accountId", clientName.accList[0].accountId);
      localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));

      //localStorage.removeItem('selectedAccountGroup');
      //localStorage.removeItem('selectedClientName');
      //localStorage.removeItem('selectedaccountIds');

      //localStorage.removeItem("selectedRegion");

      this.notifier.loading(false);

      //this.accountIds = {
      //  selected: "",
      //  list: []
      //};

      //this.regions = {
      //  selected: "",
      //  list: []
      //};
    } else {
      this.notifier.alert("Error", "Error", "Error Loading Account Groups");
      this.notifier.loading(false);
    }

    return true;
  }

  async loadJobs(monthY: Date) {
    this.loading["jobs"] = true;
    // let selected_acc = localStorage.getItem('accountId');
    // let account_details = JSON.parse(JSON.stringify(localStorage.getItem('accountIdData')));
    // for(let acc of account_details){
    //   if(account_details.accountId === selected_acc){
    //    this.acc_cftstatus = account_details.cft_status;
    //   }
    // }

    // if(this.acc_cftstatus == true){
    this.accId = localStorage.getItem("accountId");
    let monthYear: any = moment().startOf("month").format("MM-YYYY");
    if (monthY) {
      monthYear = monthY;
    }
    this.page = 1;

    // console.log(this.monthYear);
    let data: any = {
      action: "fetch",
      accId: this.accId,
      clientId: this.eId,
      selectedDate: monthYear,
    };
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      result.scans = result.scans.sort((a: any, b: any) => {
        let date_a: any = new Date(a["scannedOn"]);
        let date_b: any = new Date(b["scannedOn"]);
        return Date.parse(date_b) - Date.parse(date_a);
      });
      this.jobs = result.scans;
      let temp: any;
      this.jobs.forEach((job: any) => {
        job["scannedOn"] = moment(job["scannedOn"]).format(
          "MMM DD, YYYY hh:mm A"
        );
        job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
        job["discountPercentage"] = parseFloat(
          job["discountPercentage"]
        ).toFixed(2);
        this.discountPercentage = job.discountPercentage;
        this.totalDiscount = job.totalDiscount;
        if (this.selectedJobID && job.job_id == this.selectedJobID) {
          temp = job;
        }

        if (!job["name"]) {
          job["name"] = job["job_id"];
        }
      });
    } else {
      this.jobs = [];
    }

    this.loading["jobs"] = false;
    //   return;
    // }
    // if(this.acc_cftstatus == false){
    //   this.loading["jobs"] = false;
    //         this.jobs = [];
    //         return;
    // }
  }

  async loadChecks(accountId: string, scanId: string, status: string) {
    // console.log("greengame in Dashboard");
    // this.notifier.loading(true);
    // console.log("Loadchecks API call in dash");

    this.loading["checks"] = true;

    let data: any = {
      action: "get",
      accId: accountId,
      scanId: scanId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    // let apiURL: string = `${APIService.API_ENDPOINTV3}/market/greengame`;
    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    this.loading["checks"] = false;

    this.checksAcc = [];
    if (result.status == "1" || result.s == "1") {
      this.notifier.loading(false);
      this.checks = result.scans.map((check: any) => {
        check["passed"] = Number(check["passed"]);
        check["failed"] = Number(check["failed"]);
        check["passedPercentageForFailedLevel"] = Number(
          check["passedPercentageForFailedLevel"]
        );

        this.progresspercentage = check.passedPercentageForFailedLevel;
        return check;
      });
      // this.progresspercentage=this.job["passedPercentageForFailedLevel"];

      this.jobCurrentStatus = status;
    } else {
      // console.log("scan in progress dash");
      this.loading["checks"] = true;
      this.checks = [];
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.notifier.loading(false);
    // this.loading["checks"] = false;
  }

  async greengame(monthYear?: Date) {
    if (this.getCurrentAccount()["cft_status"]) {
      await this.loadJobs(monthYear);
      if (this.jobCurrentStatus == "Scanning") {
        this.checkProgress(monthYear);
      }
      return;
    } else {
      this.jobs = [];
    }
  }
  greengameroute() {
    this.router.navigate(["/dash/green-game"]);
  }
  async checkProgress(monthYear?: any) {
    setTimeout(async () => {
      if (this.checkCount > 10) {
        this.loading.checks = false;
        this.notifier.alert(
          "Info",
          "",
          "Security Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
          "info",
          -1
        );
        return;
      }
      this.checkCount++;
      this.checkInterval = this.checkInterval + 1000;
      await this.loadJobs(monthYear);
      if (this.jobCurrentStatus == "Scanning") {
        this.checkProgress();
      } else {
        this.loading.checks = false;
      }
    }, this.checkInterval);
  }
  getStarColor(
    index: number,
    value: number,
    result: string,
    total: number
  ): string {
    const shouldcolor = index < value;
    if (shouldcolor || (index == 3 && value == 3 && result == "passed")) {
      const baseIntensity = 70;
      const intensityIncrement = 10;
      const intensity = baseIntensity - index * intensityIncrement;
      return `hsl(120, 100%, ${intensity}%)`;
    } else {
      return "rgb(224, 216, 216)";
    }
  }

  //   getStarColor(index: number, value: number): string
  //   { console.log("index",index);
  //   console.log("value for star", value);
  //   const shouldcolor = index < value;
  //   if (shouldcolor) {
  //     let lightness: number;
  //     switch (index) {
  //       case 0: lightness = 60; break;
  //       case 1: lightness = 45; break;
  //       case 2: lightness = 35; break;
  //       default:
  //           const baseIntensity = 70;

  //       const intensityIncrement = (baseIntensity - 35) / (value - 2);
  //   // Adjusted increment based on number of stars
  //   lightness = baseIntensity - (index-2) * intensityIncrement;
  // }
  // return `hsl(120, 100%, ${lightness}%)`;
  // } else { return "rgb(224, 216, 216)"; } }

  levelsMap: { [key: string]: number } = {
    level1: 1,
    level2: 2,
    level3: 3,
  };

  checkForSwayamEMS() {
    return localStorage.getItem("emsConfig");
  }

  enableBU: boolean = false;
  async loadMonitorGroups() {
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }
    let emsData: any = JSON.parse(account);
    let data: any = {
      action: "list_monitor_groups",
      id_token: localStorage.getItem("idT"),
      zaaid: emsData["zaid"],
      login_user_name: this.username,
      login_user_email: this.userId,
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      localStorage.setItem(
        "site24x7MonitorGroups",
        JSON.stringify(result["monitor_groups"])
      );
    }

    return;
  }

  continents: any = [];

  getRegions(continent: string, enabled: boolean = true) {
    return this.regions["enabled"].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        region.id.startsWith(continent) &&
        (!this.getCurrentAccount()["customeRegions"] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  getDisabledRegions(enabled: boolean = true) {
    return this.regions["disabled"].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        (!this.getCurrentAccount()["customeRegions"] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  async loadRegions() {
    this.continents = [];
    let data: any = {
      a: "regions",
      accountId: localStorage.getItem("accountId") || null,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/globals`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.regions["enabled"] = result.data.filter((region: any) => {
        if (this.continents.indexOf(region["id"].split("-")[0]) < 0) {
          this.continents.push(region["id"].split("-")[0]);
        }
        return region["status"] == "enabled";
      });
      this.regions["disabled"] = result.data.filter((region: any) => {
        return region["status"] != "enabled";
      });
      let data = this.accountIds["list"].find((account: any) => {
        return account["accountId"] == localStorage.getItem("accountId");
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions["enabled"].find((region: any) => {
          return region.id == data.regions[0];
        });

        if (region) {
          await this.setRegion(region, false);
        } else {
          await this.setRegion(this.regions["enabled"][0], false);
        }
      } else {
        if (
          !localStorage.getItem("regionId") &&
          this.regions["enabled"].length > 0
        ) {
          await this.setRegion(this.regions["enabled"][0], false);
          return true;
        } else {
          let check = this.regions["enabled"].find((region: any) => {
            return region.id == localStorage.getItem("regionId");
          });
          if (check) {
            await this.setRegion(check, false);
            return true;
          } else if (this.regions["enabled"].length > 0) {
            await this.setRegion(this.regions["enabled"][0], false);
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.message || result.error || result.error_message,
        "info",
        5000
      );
    }
    if (this.accountIds.list.length == 0) {
      this.notifier.loading(false);
    }

    return false;
  }

  getSelectedAccountId() {
    if (this.checkForDemoAccount()) {
      return "1234567890";
    } else {
      let account = localStorage.getItem("accountId");
      if (this.selectedCloudPartner == "azure") {
        account = "xxxx-xxxx-xxxx-xx" + account.slice(-5);
      }
      return account;
    }
    // return this.checkForDemoAccount()
    //   ? "1234567890"
    //   : localStorage.getItem("accountId");
  }

  getMiddleString(text: string) {
    return text.substring(text.indexOf("(") + 1, text.lastIndexOf(")"));
  }

  filterText: string = "";
  showMore: boolean = false;

  filteredResourceGroups() {
    return this.resourceGroups.filter((rg: any) => {
      return rg.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1;
    });
  }

  selectedResourceGroup: string;
  toggleResourceGroup(rg: any) {
    rg.selected = !rg.selected;
    let rg_ = this.resourceGroups.findIndex((rg_dict: any) => {
      return rg_dict == rg;
    });
    this.resourceGroups[rg_].selected = true;
    let selectedResourceGroups = this.resourceGroups.filter((rg_: any) => {
      return rg_.selected;
    });
    if (selectedResourceGroups.length > 5) {
      if (
        rg_ == selectedResourceGroups.length - 1 ||
        rg_ == this.resourceGroups.length - 1
      ) {
        if (
          localStorage.getItem("selectedResourceGroup") ==
          selectedResourceGroups[0].name
        ) {
          selectedResourceGroups[1].selected = false;
        } else {
          selectedResourceGroups[0].selected = false;
        }
      } else {
        if (
          localStorage.getItem("selectedResourceGroup") ==
          selectedResourceGroups[selectedResourceGroups.length - 1].name
        ) {
          selectedResourceGroups[selectedResourceGroups.length - 2].selected =
            false;
        } else {
          selectedResourceGroups[selectedResourceGroups.length - 1].selected =
            false;
        }
      }
    }
  }

  async loadAccounts() {
    this.notifier.loading(true);
    this.accountIds.list = [];

    let data: any = {
      a: "fetchAccounts",
      eId:
        localStorage.getItem("masterId") &&
        localStorage.getItem("masterId") != "undefined" &&
        this.isUserAdmin
          ? localStorage.getItem("masterId")
          : localStorage.getItem("eId"),
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" && result.accounts) {
      if (result.config) {
        this.loadMonitorGroups();
        localStorage.setItem("emsConfig", JSON.stringify(result.config));
      } else {
        if (localStorage.getItem("emsConfig")) {
          localStorage.removeItem("emsConfig");
        }
      }

      if (
        localStorage.getItem("productType") == "WAFR" &&
        (!result.accounts || result.accounts.length == 0)
      ) {
        this.router.navigate(["/dash/accounts/aws"]);
        this.started = false;
        this.notifier.loading(false);
      }
      result.accounts = result.accounts.map((account: any) => {
        account["cp"] = "aws";
        if (account["regions"] == undefined) {
          account["regions"] = [];
        }
        if (account["customeRegions"] != undefined) {
          account["customRegions"] = account["customeRegions"];
        }
        if (account["customRegions"] == undefined) {
          account["customRegions"] = true;
        }
        if (account["importType"] == undefined) {
          account["importType"] = "automatic";
        }
        if (result.config) {
          account["site_247_project_id"] = result["config"]["emsUid"];
          account["site_247_project_name"] = result["config"]["emsName"];
        }
        return account;
      });
      this.accountIds.list = [...this.accountIds.list, ...result.accounts];

      let selectedAccount = localStorage.getItem("accountId");

      if (!selectedAccount) {
        localStorage.setItem("accountId", this.accountIds.list[0].accountId);
      }
      let account = this.accountIds.list.find((account: any) => {
        return account["accountId"] == selectedAccount;
      });
      if (!account) {
        localStorage.setItem("accountId", this.accountIds.list[0]["accountId"]);
      }
      localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
      let promises: any = [];

      this.accountIds.list.forEach((account: any) => {
        if (account["cp"] != "azure") {
          promises.push(this.checkForCFT(account));
        } else {
          account["cft_status"] = true;
        }
      });
      Promise.all(promises).then((values: any) => {
        localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
      });
    } else {
      this.accountIds.list = [];
      if (
        this.customerType == "mp" &&
        this.ut == "admin" &&
        !this.isUserAdmin
      ) {
        this.router.navigate(["/dash/accounts/aws"]);
        this.started = false;
      } else {
        localStorage.clear();
        this.notifier.alert(
          "Info",
          "",
          "Accounts not found. Please contact admin",
          "info",
          -1
        );
        this.router.navigate(["/login"]);
        this.notifier.loading(false);
        localStorage.clear();
        return;
      }
    }
    await this.fetchAzureSubscriptions(null);

    if (this.accountIds.list.length == 0) {
      await this.loadRegions();
    }
  }

  loadAGL() {
    this.accountGroups = JSON.parse(
      localStorage.getItem("selectedAccountGroupData")
    );
    this.clientNames = JSON.parse(
      localStorage.getItem("selectedClientNameData")
    );
    this.accountIds = JSON.parse(localStorage.getItem("accountIdData"));

    this.selectedRegion = localStorage.getItem("selectedRegion");
    //localStorage.setItem("selectedRegion", regions.name);
    localStorage.getItem("selectedRegionId");

    //for (var i = 0; i < this.agl[0].length; i++) {
    //  if()
    //}
    //this.accountGroups = {
    //  selected: this.agl[0].groupName,
    //  list: []
    //};

    //for (let i = 0; i < this.agl.length; i++) {
    //  this.accountGroups.list.push(this.agl[i]);
    //}
  }

  async selectAccountGroup(accountGroup: any, refresh = true) {
    this.accountGroups.selected = accountGroup.groupName;

    this.setAccountGroup(this.accountGroups.selected);
    return;
    let value = accountGroup.accountGroup.accountGroupList.accountGroupDetails;
    console.log("accountGroup", value);

    localStorage.setItem("selectedAccountGroup", this.accountGroups.selected);
    localStorage.setItem(
      "selectedAccountGroupData",
      JSON.stringify(this.accountGroups)
    );

    this.clientNames = {
      selected: this.accountGroups.selected,
      list: value,
    };

    //for (let i = 0; i < this.clientNames.length; i++) {
    //  if (this.clientNames.list[i].cmpName == this.clientNames.selected) {
    this.selectclientName(this.clientNames.list[0], refresh);
    //      break;
    //    }

    //}
  }

  async selectclientName(clientName: any, refresh = true) {
    this.clientNames.selected = clientName.cmpName;

    // if (!clientName.accList || clientName.accList.length == 0) {
    //   this.notifier.alert('Error', 'Error', 'No Account Found!');

    //   this.accountIds = {
    //     selected: '',
    //     list: [],
    //   };
    //   return;
    // }

    // this.accountIds = {
    //   selected: clientName.accList[0].accountName,
    //   list: clientName.accList,
    // };

    localStorage.setItem("selectedClientName", this.clientNames.selected);
    localStorage.setItem(
      "selectedClientNameData",
      JSON.stringify(this.clientNames)
    );

    this.setClientList(clientName.emailId);
    return;
    // this.notifier.changeAccountIdNotification(
    //   this.accountIds.list[0].accountId
    // );

    // this.selectAccountId(this.accountIds.list[0]);
  }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  checkForDemoAccount() {
    if (this.clientNames["selected"] == "DemoClient") {
      return true;
    }
    return false;
  }

  getSelectedAccount() {
    return this.checkForDemoAccount()
      ? "1234567890"
      : localStorage.getItem("accountId");
  }

  getSelectedRegion() {
    return localStorage.getItem("regionId");
  }

  resourceGroups: any = [];
  async fetchResourceGroups(account: any) {
    this.resourceGroups = [];

    let data: any = {
      action: "list_resource_groups",
      user_id:
        this.urlPrefix == "admin" &&
        (this.accountGroups["selected"] == "DevelopmentTestGroup" ||
          this.accountGroups["selected"] == "MinfyDevTeam")
          ? "rakeshkumar.t@minfytech.com"
          : this.userId,
      subscription_id: account["accountId"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.resourceGroups = result["resource_groups"];
      if (this.resourceGroups.length > 0) {
        this.resourceGroups.forEach((resource_group: any, index: number) => {
          if (index < 5) {
            resource_group["selected"] = true;
          }
          if (
            localStorage.getItem("selectedResourceGroup") == resource_group.name
          ) {
            this.changeResourceGroup(resource_group);
          }
        });
        if (!localStorage.getItem("selectedResourceGroup")) {
          this.changeResourceGroup(this.resourceGroups[0]);
        }
      }
      localStorage.setItem(
        "resourceGroups",
        JSON.stringify(this.resourceGroups)
      );
    }
  }

  changeResourceGroup(resource: any) {
    localStorage.setItem("selectedResourceGroup", resource["name"]);
    this.selectedResourceGroup = resource.name;
    this.notifier.changeResourceGroup(resource.name);
  }

  selectedCloudPartner: string = localStorage.getItem("cp-selected");
  async selectAccountId(accountId: any) {
    if (accountId["accountId"] == "") {
      return false;
    }

    let prev: string = this.selectedCloudPartner;

    this.selectedCloudPartner = accountId["cp"].trim();
    localStorage.setItem("cp-selected", this.selectedCloudPartner);

    this.notifier.loading(false);
    if (
      this.currentRoute.indexOf("/billing") < 0 &&
      accountId["cp"] != "azure"
    ) {
      this.checkingCFT = true;
      let result: any = await this.checkForCFT(accountId, false);
      if (result.status == "1" || result.s == "1") {
        accountId["cft_status"] = true;
      } else {
        accountId["cft_status"] = false;
      }
      this.checkingCFT = false;
    }
    this.notifier.loading(true);

    if (accountId["cp"] == "azure") {
      await this.fetchResourceGroups(accountId);
    }

    localStorage.setItem("selectedaccountIds", this.accountIds.selected);
    localStorage.setItem("accountId", accountId.accountId);
    localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));

    this.accountIds.selected =
      accountId.accountName + ` (${this.getSelectedAccountId()})`;

    if (
      !accountId["cft_status"] &&
      accountId["cft_status"] != undefined &&
      this.currentRoute.indexOf("/billing") < 0 &&
      this.selectedCloudPartner != "azure"
    ) {
      this.currentAccountCFTStatus = true;
    }
    if (this.customerType == "mp") {
      if (accountId["cft_status"]) {
        this.started = true;
      } else {
        if (window.location.href.indexOf("accounts/aws") < 0) {
          this.router.navigate(["/dash/accounts/aws"]);
        }
        this.started = false;
      }
    }
    await this.loadRegions();
    if (
      !localStorage.getItem("regionId") ||
      localStorage.getItem("regionId") == "null"
    ) {
      let data = this.accountIds["list"].find((account: any) => {
        return account["accountId"] == localStorage.getItem("accountId");
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions["enabled"].find((region: any) => {
          return region.id == data.regions[0];
        });
        if (region) {
          this.setRegion(region);
        } else {
          await this.setRegion(this.regions["enabled"][0], false);
        }
      } else if (this.regions["enabled"].length > 0) {
        await this.setRegion(this.regions["enabled"][0], false);
      }
    } else {
      this.setRegion(
        this.regions["enabled"].find((region: any) => {
          return region.id == localStorage.getItem("regionId");
        }),
        false
      );
    }
    this.notifier.loading(false);

    if (
      !localStorage.getItem("regionId") ||
      localStorage.getItem("regionId") == "null"
    ) {
      return false;
    }
    this.checkForMandatory();
    if (accountId["cp"] == "azure" && prev != accountId["cp"]) {
      this.router.navigate(["/dash/home"]);
    }
    if (this.selectedCloudPartner == "aws") {
      setTimeout(() => {
        this.notifier.changeAccountIdNotification(accountId.accountId);
        this.greengame();
      }, 100);
    } else {
      this.notifier.loading(false);
    }

    return true;
  }

  getCurrentAccount() {
    let account = localStorage.getItem("accountId");
    if (account) {
      let account_: any = this.accountIds.list.find((account_: any) => {
        return account_.accountId == account;
      });
      if (account_) {
        return account_;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  checkRegion(account: any, region_id: any) {
    return account.regions.indexOf(region_id) > -1;
  }

  async setRegion(regions: any, validate: boolean = true) {
    this.selectedRegion = regions.name;

    localStorage.setItem("selectedRegion", regions.name);
    localStorage.setItem("selectedRegionId", regions.id);
    localStorage.setItem("regionId", regions.id);

    if (validate) {
      this.notifier.changeRegionNotification(regions.id);
    }
  }

  async copyToClipboard(event: any, text: string) {
    event.preventDefault();
    event.stopPropagation();
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
  }

  async logout() {
    this.notifier.loading(true);
    let data = { eId: this.userId, ut: this.urlPrefix };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/logout`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
    }

    localStorage.clear();
    setTimeout(() => {
      this.router.navigateByUrl("/login");
      this.notifier.loading(false);
    }, 100);
  }

  hideLeftMenu() {
    $(".leftmenu").addClass("leftmenuhidden");
    $(".rightcontent").addClass("leftmenuhidden");
    $("#menuClosebtn").hide();
    $("#menuShowbtn").show();
  }

  showLeftMenu() {
    $(".leftmenu").removeClass("leftmenuhidden");
    $(".rightcontent").removeClass("leftmenuhidden");
    $("#menuClosebtn").show();
    $("#menuShowbtn").hide();
  }

  // showSearch() {
  //   this.showSearchResult = true;
  // }

  // hideSearch() {
  //   this.showSearchResult = false;
  // }

  // onSearchChange() {
  //   let searchValue = this.searchValue;
  //   if (!searchValue) return;
  //   searchValue = searchValue.toLowerCase();
  //   this.searchResults = this.searchData.items.filter((a: any) => {
  //     if (a.keys.indexOf(searchValue) != -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  goTO(link: string) {
    this.showSearchResult = false;
    this.router.navigateByUrl(link);
  }

  expandDropdown(event: any, value: string) {
    event.stopPropagation();
    this.expanded[value] = !this.expanded[value];
  }

  //for notification
  notirefe(event: any) {
    this.loadNotificationCount();
  }
  async loadNotificationCount() {
    let data = {};
    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/global_notification/count_notifications/?email_id=${this.userId}&user_type=internal`;
    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        if (data["total_notification_count"] <= 99) {
          this.noticount = data["total_notification_count"];
        } else if (data["total_notification_count"] >= 100) {
          this.noticount = "99+";
        } else {
          this.noticount = 0;
        }
      });
    } catch (error) {
      this.noticount = 0;
    }
  }
  //for dashboard setting
  show() {
    this.hideAccountTab = false;
  }
  hidePrompt(event: any) {
    this.hideAccountTab = true;
  }
  viewNotification(event: any) {
    this.viewNotificationTrigger = !this.viewNotificationTrigger;
  }
}
