import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../../../../api/api.service";
import { NotifierService } from "../../../../../../_services/notifier.service";

declare let window: any;
declare let Jhxlsx: any;

@Component({
  selector: "consolidated-snapshots-backupreport",
  styleUrls: ["./backupreport.component.css"],
  templateUrl: "./backupreport.component.html",
})
export class ConsolidatedbackupReportComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: boolean = true;
  instances: any = [];
  createScan: boolean = false;

  @Input("customHeader") customHeader: any;
  @Input("showHeader") showHeader: boolean;

  funRef: any;

  accountId: any;
  expandable: boolean = false;
  regionId: any;
  currentMessage: any;

  instancesHeader: any = [
    {
      id: "instance_name",
      name: "Instance Name",
      click: "loadSnapshots",
    },
    {
      id: "instance_id",
      name: "Instance ID",
    },
    { id: "total_volumes", name: "Total Volumes" },
    { id: "status", name: "Snapshot Status" },
    { id: "job_status", name: "Job Status" },
  ];

  snapshotsHeader: any = [
    {
      id: "volume_id",
      name: "Volume ID",
    },
    {
      id: "volume_size",
      name: "Volume Size",
    },
    {
      id: "latest_snapshot_id",
      name: "Latest Snapshot ID",
    },
    {
      id: "latest_friendly",
      name: "Latest Snapshot Created On",
    },
    {
      id: "first_snapshot_id",
      name: "First Snapshot ID",
    },
    {
      id: "first_snapshot_friendly",
      name: "First Snapshot Created On",
    },
    {
      id: "total_snapshots",
      name: "Total Snapshots",
    },
    {
      id: "snapshot_since",
      name: "Snapshot Since(Days)",
    },
    {
      id: "snapshot_backup_freq",
      name: "Snapshot Frequency",
    },
  ];
  masterId: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.masterId = localStorage.getItem('selectedClientList');
    console.log("custom headers", this.customHeader);
    console.log("Show Headers", this.showHeader);
    if (this.customHeader) {
      this.instancesHeader = this.customHeader;
      this.expandable = false;
    } else {
      this.expandable = true;
    }

    this.funRef = {
      loadSnapshots: this.loadSnapshots,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  getURl() {
    return `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;
  }

  getData() {
    return {
      action: "create_jobs",
      region_name: this.regionId,
      account_id: this.accountId,
      email: this.masterId
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  hideModal(event: any) {
    this.createScan = false;
  }

  createScanTrigger() {
    this.createScan = true;
  }

  async load() {
    this.loading = true;
    await this.loadInstances();
    this.loading = false;
  }

  async loadInstances(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
      email: this.masterId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ConsolidatedSnapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.instances = result.all_items;
      this.instances.forEach((instance: any) => {
        instance.status =
          (instance.snapshot_status ? instance.snapshot_status : 0) +
          "/" +
          instance.total_volumes;
      });
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
  }

  async loadSnapshots(ref: any, id: any) {
    if (id.showNested) {
      id.showNested = false;
      id.shapshots = [];
      return;
    }
    ref.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: ref.regionId,
      account_id: ref.accountId,
      instance_id: id.instance_id,
      email: ref.masterId,
    };

    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ConsolidatedSnapshots`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      id.showNested = true;
      id.snapshots = result['all_items'][0].volumes;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    ref.notifier.loading(false);
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    let dict: any = {
      sheetName: `Backup Report - ${this.accountId}`,
      data: [
        [
          {
            text: "Sl. No.",
          },
          ...this.instancesHeader.map((h: any) => {
            return { text: h["name"] };
          }),
        ],
      ],
    };

    this.instances.forEach((instance: any, index: number) => {
      dict.data.push([
        { text: index + 1 },
        ...this.instancesHeader.map((h: any) => {
          return { text: instance[h.id] };
        }),
      ]);
    });

    var options = {
      fileName: `Consolidated Backup Report - ${this.accountId}`,
    };

    Jhxlsx.export([dict], options);
  }

  hideNested(event: any) {
    event.showNested = false;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
