import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from '../../../../../../../api/api.service';
import { NotifierService } from '../../../../../../../_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  styleUrls: ['./create-job-ssb.component.css'],
  selector: 'consolidated-create-job-ssb',
  templateUrl: './create-job-ssb.component.html',
})
export class ConsolidatedCreateJobSSBReportComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() regionId: string;
  @Input() accountId: string;
  @Input() email: string;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = '';

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  errors: any = {
    scan_name: {
      is_error: false,
      error: '',
    },
  };
  async createJob() {
    var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(this.scan_name)) {
      this.errors['scan_name']['is_error'] = true;
      this.errors['scan_name']['error'] = 'Special Characters are not allowed.';
      return;
    } else {
      this.errors['scan_name']['is_error'] = false;
    }
    if (this.scan_name == '') {
      this.errors['scan_name']['is_error'] = true;
      this.errors['scan_name']['error'] = 'Scan Name is required.';
      return;
    } else {
      this.errors['scan_name']['is_error'] = false;
    }
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      a: "createConsolidatedJobs",
      email: this.email,
      region: this.regionId,
    };
     

    let apiUrl: string = `https://dev.api.swayam.cloud/v3/client/security/consolidatedssb`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == '1' || result.s == '1') {
      this.no(result.job_id);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
