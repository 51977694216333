<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="hideModel(false)">
        < Back</button>
          <h5>
            Security Baseline - {{job.name}}
          </h5>
    </div>
    <div class="flex-start">
      <button class="btn btn-success-soft float-right" (click)="export()">
        Export
      </button>
      <button class="btn btn-danger-soft float-right" (click)="deleteJob(job)">
        <img src="../assets/img/loading_2.svg" class="width-margin-right" alt="loading scans"
          *ngIf="loading['delete']" />
        Delete
      </button>
      <button class="btn btn-primary-soft float-right" (click)="loadChecks(activeJob.accId, activeJob.jobId)">
        Refresh
      </button>
    </div>
  </div>
  <div class="card-body">
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <ng-container *ngFor="let accId of job.accJobs; let i = index">
                <button
                    class="nav-link"
                    [class.active]="activeTab === accId"
                    [id]="'tab-' + accId"
                    [attr.data-bs-target]="'#content-' + accId"
                    type="button"
                    role="tab"
                    aria-controls="'content-' + accId"
                    [attr.aria-selected]="activeTab === accId"
                    (click)="loadChecks(accId.accId, accId.jobId)"
                    >
                    {{ accId.accId }}
                </button>
            </ng-container>
        </div>
    </nav>

</div>
</div>
<div class="card-body">
  <div class="flex-left">
    <div>
      <div class="progress-container" *ngIf="job.status == 'Completed'">
        <div class="progress-header">
          Test Score
          <img src="../assets/img/loading_2.svg" alt="loading scans" *ngIf="jobCurrentStatus == 'In progress'"
            class="wid-mar-right" />

        </div>
        <div class="progress-body">
          <div class="height-wid" id="testscore_graph"></div>
        </div>
      </div>
      <div class="progress-container" *ngIf="job.status != 'Completed'">
        <div class="progress-header">
          Scan Progress
          <img src="../assets/img/loading_2.svg" *ngIf="jobCurrentStatus == 'In progress'" alt="loading scans"
            class="wid-mar-right" />
        </div>
        <div class="progress-body">
          <div class="height-wid" id="testscore_graph"></div>
        </div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-header">Check Types</div>
      <div class="progress-body">
        <div id="graphMain" class="height-wid" *ngIf="!loading['checks']">
        </div>
        <div class="position-width-height" *ngIf="loading['checks']">
          <div class="round_loader loader"></div>
        </div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-header">Failed Checks</div>
      <div class="progress-body">
        <div id="failedGraph" class="height-wid" *ngIf="!loading['checks']">
        </div>
        <div class="position-width-height" *ngIf="loading['checks']">
          <div class="round_loader loader"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="progress-container">
    <div class="progress-header">
      <div>
        Checks
      </div>
      <button class="btn btn-light filter_click">
        <i class="fa fa-filter" aria-hidden="true"></i>
        <div class="font-em">
          <div>
            {{ filter['checks'] }}
          </div>
        </div>
        <div class="filter_dropdown">
          <div class="filter-item" (click)="filter['checks'] = 'ALL'">
            <div class="filter-checkbox" [ngClass]="{checked: filter['checks'] == 'ALL'}">

            </div>
            <div>
              All
            </div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Automatic'">
            <div class="filter-checkbox" [ngClass]="{checked: filter['checks'] == 'Automatic'}">

            </div>
            <div>
              Automatic
            </div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Manual'">
            <div class="filter-checkbox" [ngClass]="{checked: filter['checks'] == 'Manual'}">

            </div>
            <div>
              Manual
            </div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Passed'">
            <div class="filter-checkbox" [ngClass]="{checked: filter['checks'] == 'Passed'}">

            </div>
            <div>
              Passed
            </div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Failed'">
            <div class="filter-checkbox" [ngClass]="{checked: filter['checks'] == 'Failed'}">

            </div>
            <div>
              Failed
            </div>
          </div>
        </div>
      </button>
    </div>
    <div class="progress-body">
      <div [ngClass]="{expanded: check.expanded}" *ngFor="let check of filterChecks()">
        <div class="check" (click)="showCheck(check)" id="check_{{ check['check_id'] }}">
          <div class="check_inner" [attr.data-status]="check.type">
            <div class="status"
              [ngStyle]="{'background-color': ((check.failed > 0 || (check.type != 'Manual' && check.failed == 0 && check.passed == 0)) && 'rgb(243, 115, 115)') ||( (check.passed > 0 && check.failed == 0) && 'rgb(92, 156, 92)') || check.type == 'Manual' && 'rgb(240 175 30)'}">
              <ng-container class="status"
                *ngIf="check.failed > 0 || (check.type != 'Manual' && check.failed == 0 && check.passed == 0)">
                Failed
              </ng-container>
              <ng-container class="status" *ngIf="check.passed > 0 && check.failed == 0">
                Passed
              </ng-container>
              <ng-container class="status" *ngIf="check.type == 'Manual'">
                Manual
              </ng-container>
            </div>
            <div class="flex-pad">
              <div class="display-flex-space-center">
                <div class="flex-1">
                  <div class="font-pointer">{{ check.name }}</div>
                </div>
              </div>
              <div class="nav-link pad-size">
                RULE ID: {{ check.check_id }}
              </div>
              <div class="tag_list">
                <div class="tag">
                  <div class="tag_item">{{ check['failed'] + check['passed'] }}</div>
                  <div class="tag_item">Tested</div>
                </div>
                <div class="tag passed">
                  <div class="tag_item">{{ check['passed'] }}</div>
                  <div class="tag_item">Passed</div>
                </div>
                <div class="tag failed">
                  <div class="tag_item">{{ check['failed'] }}</div>
                  <div class="tag_item">Failed</div>
                </div>
              </div>
            </div>
            <div class="down-arrow">
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div *ngIf="check.expanded" class="mb-2 pad-25" (click)="prevent($event)">
          <div class="font-border1">
            <span>
              Findings
            </span>
            <div class="display-flex-end">
              <button class="btn btn-light filter_click">
                <i class="fa fa-filter" aria-hidden="true"></i>
                <div class="font-em">
                  <div>
                    {{ filter['findings'] }}
                  </div>
                </div>
                <div class="filter_dropdown">
                  <div class="filter-item" (click)="filter['findings'] = 'ALL'">
                    <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'ALL'}">

                    </div>
                    <div>
                      All
                    </div>
                  </div>
                  <div class="filter-item" (click)="filter['findings'] = 'Passed'">
                    <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'Passed'}">

                    </div>
                    <div>
                      Passed
                    </div>
                  </div>
                  <div class="filter-item" (click)="filter['findings'] = 'Failed'">
                    <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'Failed'}">

                    </div>
                    <div>
                      Failed
                    </div>
                  </div>
                </div>
              </button>
              <div class="display-content-end" *ngIf="check['pagination']">
                <div>
                  Per Page:
                </div>
                <div>
                  <select class="pad-background" [(ngModel)]="check['pagination']['per_page']"
                    (change)="changePerPage(check)">
                    <option [ngValue]="count" *ngFor="let count of perPages">{{ count }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!check['loading']">
            <div class="finding" *ngFor="let finding of filterFindings(check.findings) | paginate
              : {
                  itemsPerPage: check['pagination'].per_page,
                  currentPage: check['pagination'].page,
                  totalItems: filterFindings(check.findings).length
                }; let i = index">
              <div class="mb-2">
                <div class="display-content-start">
                  <h5 class="flex-1">{{ (check['pagination']['per_page'] * (check['pagination']['page'] - 1)) + (i +
                    1) }}. {{ finding['name'] }}
                  </h5>
                  <div><span *ngIf="finding['resource']">
                      Resource: {{ finding['resource'] }}
                    </span> <span *ngIf="finding['region']">| Region: {{
                      finding['region'] }}</span></div>
                </div>
              </div>
              <ng-container *ngIf="finding['status'] == 'Manual' || finding['status'] == false">
                <div class="mb-2">
                  <div class="font-bold">Reason</div>
                  <div>{{ finding['reason'] }}</div>
                </div>
                <div class="mb-2 display-flex-item-gap" *ngIf="finding['fix']">
                  <div><a target="_blank" href="{{ finding['fix'] }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <span>Fix</span> <i class="fa fa-external-link" aria-hidden="true"></i>
                      </span>
                    </a></div>
                  <div *ngIf="finding['actions'] && getObjectKeys(finding['actions']).length > 0">
                    <div *ngFor="let key of getObjectKeys(finding['actions'])"><a target="_blank"
                        href="{{ finding['actions'][key] }}" class="btn btn-primary-soft display-flex-gap"><span>
                          <img src="../assets/img/aws_logo_1.png" alt="" class="height14">
                          <span>{{ key }}</span>
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </span>
                      </a></div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="finding['status'] == true">
                Passed
              </ng-container>
            </div>
            <div class="paginator">
              <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
                (pageChange)="onTableDataChange($event, check)">
              </pagination-controls>
            </div>
          </ng-container>
          <ng-container *ngIf="check['loading']">
            <div class="text-cent">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar10" />
            </div>
          </ng-container>
        </div>
      </div>
      <div class="text-cent" *ngIf="loading['checks']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar-right" />
      </div>
    </div>
  </div>
</div>