<div class="card">
  <div class="card-body">
    <div class="sections">
      <div class="section">
        <div class="section_header" (click)="loadEC2Data()">
          <div class="section_title">EC2</div>
          <div class="flex-start">
            <div [innerHTML]="response['finalEC2HTML']"></div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="ec2Show" class="overflow-auto">
          <div class="flex-margin" *ngIf="ec2Data.length > 0">
            <button class="btn btn-light back-color" (click)="exportEC2()">
              Export
            </button>
          </div>

          <!-- Savings Plan Covered Usage -->
          <div
            *ngIf="getEC2SubcategoryData('SavingsPlanCoveredUsage').length > 0"
          >
            <div class="ec2-subcategory flex-between" (click)="toggleExpanded('SavingsPlanCoveredUsage')">
              <div class="section_title fw-bold">Savings Plan Covered Usage</div>
              <div class="savings-data">
                <div class="savings-text fw-bold">
                  ${{ response["savingsPlanNegation"].toFixed(4) }} /
                  ${{ response["savingsPlanCoveredUsage"].toFixed(4) }} 
                  ({{ response["savingsPercentage"].toFixed(2) }}%)
                </div>
                <div class="progress-wrapper">
                  <div class="progress-container" [ngStyle]="{backgroundColor: response['savingsPercentage'] < 80 ? 'lightgreen' : 'lightpink'}">
                    <div class="progress" [ngStyle]="{ 
                      width: response['savingsPercentage'] + '%', 
                      backgroundColor: response['savingsPercentage'] < 80 ? 'green' : 'red' 
                    }"></div>
                  </div>
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
            <div class="ec2-subcategory-content" *ngIf="expanded['SavingsPlanCoveredUsage']">
              <div class="table-container" (scroll)="onScroll($event, 'SavingsPlanCoveredUsage')">
                <table class="table table-docs table-hover">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <ng-container *ngFor="let h of ec2Headers">
                        <th
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          {{ h.name }}
                        </th>
                        <th *ngIf="!h.hide && !h.displayConditional">
                          {{ h.name }}
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let i of visibleData['SavingsPlanCoveredUsage'];
                        let j = index
                      "
                    >
                      <td>{{ j + 1 }}</td>
                      <ng-container *ngFor="let h of ec2Headers">
                        <td
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                        <td *ngIf="!h.hide && !h.displayConditional">
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngIf="visibleData['SavingsPlanCoveredUsage'].length === 0">
                      <td colspan="100%">No data available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Usage -->
          <div *ngIf="getEC2SubcategoryData('Usage').length > 0">
            <div class="ec2-subcategory" (click)="toggleExpanded('Usage')">
              <div class="section_title fw-bold">Usage</div>
              <i class="fa fa-caret-down"></i>
            </div>
            <div class="ec2-subcategory-content" *ngIf="expanded['Usage']">
              <div class="table-container" (scroll)="onScroll($event, 'Usage')">
                <table class="table table-docs table-hover">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <ng-container *ngFor="let h of ec2Headers">
                        <th
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          {{ h.name }}
                        </th>
                        <th *ngIf="!h.hide && !h.displayConditional">
                          {{ h.name }}
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let i of visibleData['Usage'];
                        let j = index
                      "
                    >
                      <td>{{ j + 1 }}</td>
                      <ng-container *ngFor="let h of ec2Headers">
                        <td
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                        <td *ngIf="!h.hide && !h.displayConditional">
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngIf="visibleData['Usage'].length === 0">
                      <td colspan="100%">No data available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Spot -->
          <div *ngIf="getEC2SubcategoryData('Spot').length > 0">
            <div class="ec2-subcategory" *ngIf="response['savingsPlanCoveredUsage'] != 0" (click)="toggleExpanded('Spot')">
              <div class="section_title fw-bold">Spot</div>
              <i class="fa fa-caret-down"></i>
            </div>
            <div class="ec2-subcategory-content" *ngIf="expanded['Spot']">
              <div class="table-container" (scroll)="onScroll($event, 'Spot')">
                <table class="table table-docs table-hover">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <ng-container *ngFor="let h of ec2Headers">
                        <th
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          {{ h.name }}
                        </th>
                        <th *ngIf="!h.hide && !h.displayConditional">
                          {{ h.name }}
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let i of visibleData['Spot'];
                        let j = index
                      "
                    >
                      <td>{{ j + 1 }}</td>
                      <ng-container *ngFor="let h of ec2Headers">
                        <td
                          *ngIf="
                            !h.hide && h.displayConditional && getValue(h.id)
                          "
                        >
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                        <td *ngIf="!h.hide && !h.displayConditional">
                          <span
                            *ngIf="!h.click"
                            [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                          ></span>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngIf="visibleData['Spot'].length === 0">
                      <td colspan="100%">No data available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="display-flex-right" *ngIf="nextTokens['ec2']">
            <button
              class="btn btn-light back-color"
              (click)="loadEC2Data(true)"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadEBSData()">
          <div class="section_title">EBS</div>
          <div class="flex-start">
            <div [innerHTML]="getTotalEBSFinal()"></div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="ebsShow" class="overflow-auto">
          <div class="flex-margin" *ngIf="ebsData.length > 0">
            <button class="btn btn-light back-color" (click)="exportEBS()">
              Export
            </button>
          </div>
          <table class="table table-docs table-hover" id="ebs-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of ebsHeaders">
                  <ng-container
                    *ngIf="h.show === undefined || h.show() === true"
                  >
                    <th
                      *ngIf="!h.hide && h.displayConditional && getValue(h.id)"
                    >
                      {{ h.name }}
                    </th>
                    <th *ngIf="!h.hide && !h.displayConditional">
                      {{ h.name }}
                      <span *ngIf="h.filter" class="inputseach">
                        <input
                          autocomplete="off"
                          type="text"
                          [(ngModel)]="h.filterText"
                        />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </th>
                  </ng-container>
                </ng-container>
              </tr>
            </thead>

            <tbody
              [ngStyle]="{
                display: ebsData.length > 0 ? 'table-row-group' : 'none'
              }"
            >
              <ng-container *ngFor="let i of ebsData; let j = index">
                <tr *ngIf="notifier.filterTableColums(ebsHeaders, i)">
                  <td>{{ j + 1 }}</td>
                  <ng-container *ngFor="let h of ebsHeaders">
                    <ng-container
                      *ngIf="h.show === undefined || h.show() === true"
                    >
                      <td
                        *ngIf="
                          !h.hide && h.displayConditional && getValue(h.id)
                        "
                      >
                        <span
                          *ngIf="!h.click"
                          [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"
                        >
                        </span>
                      </td>
                      <td *ngIf="!h.hide && !h.displayConditional">
                        <span
                          *ngIf="!h.click"
                          [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"
                        >
                        </span>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
              <tr *ngIf="!this.nextTokens['ebs']">
                <td
                  [attr.colSpan]="
                    ebsHeaders.length -
                    5 -
                    (customerType == 'mp' ? 1 : 0) -
                    (projectTagNameEBS ? 0 : 1) -
                    (mapMigratedEBS ? 0 : 1)
                  "
                ></td>
                <td>SUB Total</td>
                <td>
                  {{ getTotalEBS(ebsData)["gb_cost"] }}
                </td>
                <td>
                  {{ getTotalEBS(ebsData)["storage"] }}
                </td>
                <td *ngIf="IOPSshow">
                  {{ getTotalEBS(ebsData)["iops"] }}
                </td>
                <td></td>
                <td>
                  {{ getTotalEBS(ebsData)["total"] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="ebsData.length == 0">
              <tr>
                <td [attr.colspan]="ebsHeaders.length + 1 - (IOPSshow ? 0 : 1)">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="display-flex-right" *ngIf="nextTokens['ebs']">
            <button
              class="btn btn-light back-color"
              (click)="loadEBSData(true)"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadOthersData()">
          <div class="section_title">Others</div>
          <div class="flex-start">
            <div [innerHTML]="getTotalOthersFinal()"></div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="othersShow" class="overflow-auto">
          <div class="flex-margin" *ngIf="othersData.length > 0">
            <button class="btn btn-light back-color" (click)="exportOthers()">
              Export
            </button>
          </div>
          <table class="table table-docs table-hover" id="others-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th *ngFor="let h of othersHeaders">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody
              [ngStyle]="{
                display: othersData.length > 0 ? 'table-row-group' : 'none'
              }"
            >
              <tr *ngFor="let i of othersData; let j = index">
                <td>{{ j + 1 }}</td>
                <td *ngFor="let h of othersHeaders">
                  <span
                    *ngIf="!h.click"
                    [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"
                  >
                  </span>
                </td>
              </tr>
              <tr *ngIf="!this.nextTokens['others']">
                <td [attr.colSpan]="othersHeaders.length - 1"></td>
                <td>SUB Total</td>
                <td>
                  {{ getTotalOthers(othersData) }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="othersData.length == 0">
              <tr>
                <td [attr.colspan]="othersHeaders.length + 1">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="display-flex-right" *ngIf="nextTokens['others']">
            <button
              class="btn btn-light back-color"
              (click)="loadOthersData(true)"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
