import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare let window: any;
@Component({
  templateUrl: "./enable.reports.component.html",
  styleUrls: [
    "../../ticket/ticket.component.css",
    "./enable.reports.component.css",
  ],
})
export class enableReportsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  reports: any = [];
  reportType: string = "daily";
  additionalContacts: string[] = [""];
  action: string = "enable";

  funRef: any;

  reportsMapper: any = {
    amis: {
      name: "AMIs",
      description: "AMIs",
    },
    consolidatedCompliance: {
      name: "Consolidated Compliance",
      description: "Report",
    },
    consolidatedInfra: {
      name: "Consolidated Infra",
      description: "Report",
    },
    infrastructure: {
      name: "Infrastructure",
      description: "Report",
      site: true,
      message: "EMS Integration is mandatory",
    },
    jira: {
      name: "JIRA",
      description: "Report",
      jira: true,
      message: "Jira Project Key, Jira Oraganization Name are mandatory fields",
    },
    rdsbackup: {
      name: "RDS Backup",
      description: "Report",
    },
    snapshots: {
      name: "Snapshots",
      description: "Report",
    },
    inventory: {
      name: "Inventory",
      description: "Report",
    },
    config: {
      name: "Config",
      description: "Report",
    },
    uptime_report: {
      name: "Uptime Report",
      description: "Report",
      site: true,
      message: "EMS Integration is mandatory",
    },
    securityhub: {
      name: "Security Hub",
      description: "Report",
    },
    dailyBillingVariance: {
      name: "Daily Cost Variance",
      description: "Dailt Cost Variance",
    },
    "MBR Report": {
      name: "MBR Report",
      description:
        "Monthly Billing Report: Comprehensive summary of AWS account charges and usage for the previous month",
    },
    backupreport: {
      name: 'Backup Report',
      description: 'Report',
    }
  };
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  async ngOnInit() {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != "" &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push("");
    } else if (
      value == "" &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ""
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.additionalContacts.push("");
  }

  trackByFn() {
    return true;
  }

  ngOnDestroy() {
    this.currentMessage.unsubscribe();
  }

  changeReportType(value: string) {
    this.reportType = value;
    this.load();
  }

  loading: boolean = true;
  jira_access: boolean = false;
  site_24x7_access: boolean = false;
  async load() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const {
          jira_project_key,
          jira_org_name,
          site_247_project_id,
          accountName,
        } = selectedAccountData.data;
        if (jira_org_name == "" || jira_project_key == "") {
          this.jira_access = false;
        } else {
          this.jira_access = true;
        }
        if (site_247_project_id == "") {
          this.site_24x7_access = false;
        } else {
          this.site_24x7_access = true;
        }
      }
    } catch (err) {
      this.jira_access = false;
      this.site_24x7_access = false;
    }
    this.loading = true;
    this.action = "enable";
    this.additionalContacts = [""];
    this.reports = [];
    let promises: any = [];
    promises.push(this.fetchReportTypes());
    Promise.all(promises).then((values: any) => {
      this.loading = false;
    });
  }

  async fetchReportTypes() {
    return new Promise(async (resolve: any, reject: any) => {
      let data = { action: "fetch", reportType: this.reportType };

      let header = {
        Authorization: localStorage.getItem("t"),
      };
      window.analyticsSendEventClick("Reports.Enable", "ReportTypes Fetched");
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/enable`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);
      if (result.status == "1" || result.s == "1") {
        this.reports = result.reportList.map((report: any) => {
          return { report_type: report, selected: false };
        });
        await this.fetchSelectedReportTypes();
      } else {
        this.notifier.alert("Error", "", result.msg, "error", 5000);
      }
      console.log("reports", this.reports);
      resolve(true);
    });
  }

  prevContacts: any = [];
  arrayEquals(a: any, b: any) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  async fetchSelectedReportTypes() {
    let data = {
      action: "fetchEnableReports",
      reportType: this.reportType,
      region: this.regionId,
      accountId: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick(
      "Reports.Enable",
      "SelectedReportTypes Fetched"
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/enable`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      if (result.reportDetails.reportList.length > 0) {
        this.action = "disable";
      } else {
        this.action = "enable";
      }
      this.reports.map((report: any) => {
        result.reportDetails.reportList.map((report_: any) => {
          if (report.report_type == report_) {
            report["selected"] = true;
          }
        });
      });
      this.prevContacts = [...result.reportDetails.emailList, ""];
      this.additionalContacts = [...result.reportDetails.emailList, ""];
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }
  }

  selectedLength(): number {
    return this.reports.filter((report: any) => {
      return report.selected;
    }).length;
  }

  totalAdditionalContacts() {
    return this.additionalContacts.filter((contact: any) => {
      return contact != "";
    }).length;
  }

  async toggleReports(reportType?: any) {
    if (this.totalAdditionalContacts() == 0) {
      return;
    }
    if (reportType) {
      reportType["loading"] = true;
    }
    if (this.selectedLength()) {
      await this.enableReports("enable", true);
    } else {
      await this.enableReports("disable", true);
    }
    if (reportType) {
      reportType["loading"] = false;
    }
  }

  enabling: boolean = false;
  action_running: string;
  async enableReports(action_: string, force: boolean = false) {
    if (
      !this.arrayEquals(this.prevContacts, this.additionalContacts) &&
      force == false
    ) {
      return;
    }
    let emails: string[] = [];
    let check: boolean = false;
    this.additionalContacts.forEach((email: string) => {
      if (email.trim() != "") {
        if (emails.includes(email.trim())) {
          this.notifier.alert("Info", "", "Repeated Email IDs", "info", 5000);
          check = true;
          // break;
          return;
        } else {
          emails.push(email.trim());
        }
      }
    });
    if (check) {
      return;
    }
    this.action_running = action_;
    this.enabling = true;
    if (
      this.additionalContacts.filter((contact: any) => {
        return contact != "";
      }).length == 0
    ) {
      if (
        force &&
        this.arrayEquals(this.prevContacts, this.additionalContacts)
      ) {
        this.notifier.alert("Info", "", "Email Ids are must", "info", 5000);
      }
      return;
    }
    if (!this.writeAccess) {
      return;
    }
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const {
          jira_project_key,
          jira_org_name,
          site_247_project_id,
          accountName,
        } = selectedAccountData.data;
        let data;
        if (action_ == "enable") {
          let selectedClientData = JSON.parse(
            localStorage.getItem("clientList")
          );
          let client_id;
          if (this.urlPrefix == "admin") {
            client_id = selectedClientData.find((client: any) => {
              return (
                client.emailId == localStorage.getItem("selectedClientList")
              );
            })["emailId"];
          } else {
            client_id = this.userId;
          }
          let com_name;
          if (this.urlPrefix == "admin") {
            com_name = selectedClientData.find((client: any) => {
              return (
                client.emailId == localStorage.getItem("selectedClientList")
              );
            })["cmpName"];
          } else {
            com_name = localStorage.getItem("com");
          }
          data = {
            action: "enableReports",
            reportType: this.reportType,
            reportList: this.reports
              .filter((report: any) => {
                return report.selected;
              })
              .map((report: any) => {
                return report.report_type;
              }),
            emailList: this.additionalContacts.filter((contact: any) => {
              return contact != "";
            }),
            jiraProjectKey: jira_project_key || "-",
            jiraOrganisationKey: jira_org_name || "-",
            site24x7ProjectKey: site_247_project_id || "-",
            region: this.regionId,
            accountId: this.accountId,
            accountName: accountName,
            companyName: com_name,
            clientId: client_id,
          };
        } else if (action_ == "disable") {
          data = {
            action: "disableReports",
            reportType: this.reportType,
            region: this.regionId,
            accountId: this.accountId,
          };
        }

        let header = {
          Authorization: localStorage.getItem("t"),
        };
        window.analyticsSendEventClick("Reports.Enable", "Reports Enabled");
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/enablev2`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);
        if (result.status == "1" || result.s == "1") {
          if (data.reportList.length == this.selectedLength()) {
            this.notifier.alert("Success", "", result.msg, "success", 5000);
          }
          this.prevContacts = JSON.parse(
            JSON.stringify(this.additionalContacts)
          );
        } else {
          this.notifier.alert(
            "Info",
            "",
            result.msg || result.error,
            "info",
            5000
          );
        }
      }
    } catch (err) {}
    this.enabling = false;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }
}
