import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-consolidated-ssb-report",
  templateUrl: "./ssb.component.html",
  styleUrls: ["./ssb.component.css"],
})
export class ConsolidatedSsbComponent implements OnInit, OnDestroy {
  jobs: any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: string;
  regionId: string;
  loading: any = {
    jobs: false,
  };
  email: any;
  clientEmail:any;
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  selectedJobName: any;
  jobsHeaders: any = [
    {
      id: "name",
      name: "Name",
      link: true,
    },
    // {
    //   id: "cr_time",
    //   name: "Creation Time",
    // },
    // {
    //   id: "total_checks",
    //   name: "Total Checks",
    // },
    // {
    //   id: "perc",
    //   name: "Score",
    // },
    // {
    //   id: "passed",
    //   name: "#Passed",
    // },
    // {
    //   id: "failed",
    //   name: "#Failed",
    // },
    // {
    //   id: "status",
    //   name: "Status",
    // },
    // {
    //   id: "cm_time",
    //   name: "Completed Time",
    // },
  ];
  currentMessage: any;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.selectedJob = null;
        this.selectedJobID = null;
      }
      if (params["jobId"] && params["jobId"] != "") {
        this.selectedJobID = params["jobId"];
      }
      if (params["job_name"] && params["job_name"] != "") {
        this.selectedJobName = params["job_name"];
      }
    });

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  fetchSelectedClientDetails() {
    let clientList: any = localStorage.getItem("clientList");

    if (clientList && localStorage.getItem("role") == "Admin") {
      let selectedCLient: string = localStorage.getItem("selectedClientList");
      let data: any = JSON.parse(clientList);
      const client = data.find((client: any) => {
        return client["emailId"] == selectedCLient;
      });
      return client["emailId"] || null;
    } else {
      return localStorage.getItem("masterId") || localStorage.getItem("eId");
    }
  }

  load(event: any = true) {
    this.selectedJob = null;
    if (event) {
      this.loadJobs();
    }
  }

  async loadJobs(jobId?: string) {
    this.loading["jobs"] = true;
    this.clientEmail= this.fetchSelectedClientDetails();

    this.email = (this.clientEmail != 'undefined' && this.clientEmail) || (localStorage.getItem('masterId') != 'undefined' && localStorage.getItem('masterId')) || localStorage.getItem('eId')

    this.page = 1;

    let data: any = {
      a: "ListConsolidatedSSBJobs",
      email: this.email,
      region: this.regionId,
    };


    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `https://dev.api.swayam.cloud/v3/client/security/consolidatedssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      // result.jobs = result.jobs.sort((a: any, b: any) => {
      //   let date_a: any = new Date(a["cr_time"]);
      //   let date_b: any = new Date(b["cr_time"]);
      //   return Date.parse(date_b) - Date.parse(date_a);
      // });
      this.jobs = result.jobs;
      let temp: any;
      this.jobs.forEach((job: any) => {
        if (this.selectedJobID && job.jobId == this.selectedJobID) {
          temp = job;
        }
        if (this.selectedJobName && job.name == this.selectedJobName) {
          temp = job;
        }
        if (!job["name"]) {
          job["name"] = job["jobId"];
        }
      });

      if (temp) {
        this.selectedJob = temp;
      } else {
        this.navigator.navigate(["dash/reports/view?reportType=consolidated_security_baseline"]);
      }
    } else {
      this.jobs = [];
    }

    this.loading["jobs"] = false;
  }

  selectJob(job: any) {
    this.selectedJob = job;
    this.selectedJobID = job["jobId"];
    this.navigator.navigate(["dash/reports/view?reportType=consolidated_security_baseline"], {
      queryParams: { jobId: this.selectedJobID },
    });
    return true;
  }

  hideModel(event: string) {
    if (event) {
      this.navigator.navigate(["dash/reports/view?reportType=consolidated_security_baseline"], {
        queryParams: { jobId: event },
      });
      this.selectedJobID = event;
      this.loadJobs();
    }
    this.createJob = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
