import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import * as echarts from "echarts";
import { Router } from "@angular/router";

declare let Jhxlsx: any;

@Component({
  selector: "consolidated-app-ssb-check",
  templateUrl: "./ssb-check-component.component.html",
  styleUrls: ["./ssb-check-component.component.css"],
})
export class ConsolidatedSsbCheckComponentComponent implements OnInit, OnDestroy {
  @Input("loading") loading: any;
  @Input("job") job: any;
  @Input("accountId") accountId: any;
  @Input("regionId") regionId: any;
  @Output("hideModel") hide: any = new EventEmitter<boolean>();
  @Output("loadJobs") loadJobs: any = new EventEmitter<string>();
  perPages: any = [10, 50, 100, 200];
  checks: any = [];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  jobsHeaders: any = [
    {
      id: "job_id",
      name: "Job ID",
    },
  ];
  currentMessage: any;
  filter: any = {
    checks: "ALL",
    findings: "ALL",
  };

  checkCount = 0;
  checkInterval = 10000;
  jobCurrentStatus = ""; //In progress, Completed
  activeTab:any;
  activeJob: { accId: any; jobId: any; };
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    if (this.job.accIds.length > 0 && this.job.accJobs.length > 0) {
      this.activeTab = this.job.accIds[0]; // Set the first accId as the active tab
      const initialJob = this.job.accJobs.find((j:any) => j.accId === this.activeTab);
      if (initialJob) {
        this.activeJob = { accId: initialJob.accId, jobId: initialJob.jobId }; // Set the first job as active
      }
      console.log('Initial Active Job:', this.activeJob);
    }
    console.log('job',this.job);
    this.loading["checks"] = true;
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  destroyed: boolean = false;

  async load() {
    //this.loading["checks"] = true;
    //if (this.job.status != "Completed") {
    //  this.loadJobs.emit(this.job.jobId);
    //  this.loadChecks();
    //  if (this.timer) {
    //    return;
    //  }
    //  this.timer = setInterval(() => {
    //    if (this.job.status != "Completed" && this.destroyed == false) {
    //      this.loadJobs.emit(this.job.jobId);
    //      this.loadChecks();
    //    } else {
    //      if (this.timer) {
    //        clearInterval(this.timer);
    //        this.timer = null;
    //      }
    //    }
    //  }, 5000);
    //} else {
    //  if (this.timer) {
    //    clearInterval(this.timer);
    //    this.timer = null;
    //  }
    //  this.loadChecks();
    //}

    await this.loadChecks(this.job.accJobs[0].accId, this.job.accJobs[0].jobId);
    if (this.jobCurrentStatus == "In progress") {
      this.checkProgress(this.job.accJobs[0].accId, this.job.accJobs[0].jobId);
    }
  }

  async checkProgress(accId:any, jobId:any) {
    setTimeout(async () => {
      if (this.checkCount > 10) {
        this.loading.checks = false;
        this.notifier.alert(
          "Info",
          "",
          "Security Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
          "info",
          -1
        );
        return;
      }
      this.checkCount++;
      this.checkInterval = this.checkInterval + 1000;
      await this.loadChecks(accId, jobId);
      if (this.jobCurrentStatus == "In progress") {
        this.checkProgress(accId, jobId);
      } else {
        this.loading.checks = false;
      }
    }, this.checkInterval);
  }

  hideModel(event: any) {
    this.navigator.navigate(["dash/reports/view?reportType=consolidated_security_baseline"]);
    this.hide.emit(event);
  }

  checksAcc: any = [];

  async loadChecks(accId:any, jobId:any) {
    this.loading["checks"] = true;
    const jb = this.job.accJobs.find((j:any) => j.accId === accId);
    if (jb) {
      this.activeTab = accId;
      this.activeJob = { accId: jb.accId, jobId: jb.jobId };
      console.log('Active Job:', this.activeJob);
    }

    let data: any = {
      //action: "list_checks",
      action: "job_status",
      job_id: jobId,
      account_id: accId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    this.loading["checks"] = false;

    this.checksAcc = [];
    if (result.status == "1" || result.s == "1") {
      result.checks = result.checks.map((check: any) => {
        let check1 = this.checks.find((check_: any) => {
          return check_.check_id == check.check_id;
        });
        if (check1) {
          check = check1;
        }
        let temp = this.checksAcc.find((check_: any) => {
          return check_["check_id"] == check.check_id;
        });
        if (!temp) {
          this.checksAcc.push({
            check_id: check.check_id,
            failed: check.failed,
          });
        } else {
          temp.failed += check.failed;
        }
        return check;
      });
      this.checks = result.checks;
      setTimeout(() => {
        this.loadGraph();
        this.loadFailedGraphs();
        //if (this.job.status == "Completed") {
        if (result.job_status[0]["status"] == "Completed") {
          this.checks.forEach((check: any) => {
            this.loadCheckDetailed(check);
          });
          this.loadTestScoreGraph(
            Number(result.job_status[0]["perc"]).toFixed(1)
          );
        } else {
          let perc: any = (this.checks.length / this.job.total_checks) * 100;
          this.loadTestScoreGraph(Number(perc).toFixed(1), "Scanning...");
        }
      }, 10);

      this.jobCurrentStatus = result.job_status[0]["status"];
    } else {
      this.checks = [];
    }
  }

  getTotalPages(pagintation: any, total_count: number) {
    return new Array(Math.ceil(total_count / pagintation["per_page"]));
  }

  showCheck(check: any) {
    if (check["expanded"]) {
      check["expanded"] = false;
      return;
    } else {
      check["expanded"] = true;
    }
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    let dict: any = {
      sheetName: `SSB Report`,
      data: [],
    };

    dict.data.push([
      {
        text: "Name",
      },
      {
        text: "Creation Time",
      },
      {
        text: "Total Checks",
      },
      {
        text: "Score",
      },
      {
        text: "#Passed",
      },
      {
        text: "#Failed",
      },
      {
        text: "Status",
      },
      {
        text: "Completed Time",
      },
    ]);
    dict.data.push([
      {
        text: this.job.name || "",
      },
      {
        text: this.job.cr_time || "",
      },
      {
        text: this.job.total_checks || "",
      },
      {
        text: this.job.perc || "",
      },
      {
        text: this.job.passed || "",
      },
      {
        text: this.job.failed || "",
      },
      {
        text: this.job.status || "",
      },
      {
        text: this.job.cm_time || "",
      },
    ]);

    dict.data.push([{ text: "" }]);
    dict.data.push([{ text: "Checks" }]);

    dict.data.push([
      {
        text: "Sl. No.",
      },
      {
        text: "Name",
      },
      {
        text: "Type",
      },
      {
        text: "Total",
      },
      {
        text: "Passed",
      },
      {
        text: "Failed",
      },
    ]);
    this.checks.forEach((check: any, index: number) => {
      dict.data.push([
        {
          text: index + 1,
        },
        {
          text: check["name"] || 0,
        },
        {
          text: check["type"] || 0,
        },
        {
          text: (check["passed"] || 0) + (check["failed"] || 0) || "-",
        },
        {
          text: check["passed"] || "-",
        },
        {
          text: check["failed"] || "-",
        },
      ]);

      dict.data.push([
        {
          text: "",
        },
        {
          text: "Findings",
        },
      ]);

      if (check.findings.length > 0) {
        dict.data.push([
          {
            text: "",
          },
          {
            text: "Sl. No.",
          },
          {
            text: "Check ID",
          },
          {
            text: "Name",
          },
          {
            text: "Reason",
          },
          {
            text: "Resource",
          },
          {
            text: "Status",
          },
        ]);

        check.findings.forEach((finding: any, check_no: number) => {
          dict.data.push([
            {
              text: "",
            },
            {
              text: check_no + 1,
            },
            {
              text: finding["check_id"] || "",
            },
            {
              text: finding["name"] || "",
            },
            {
              text: finding["reason"] || "",
            },
            {
              text: finding["resource"] || "",
            },
            {
              text:
                finding["status"].toString().toLowerCase() == "manual"
                  ? "Manual"
                  : finding["status"]
                  ? "Passed"
                  : "Failed",
            },
          ]);
        });
      }

      dict.data.push([
        {
          text: "",
        },
      ]);
    });

    var options = {
      fileName: `SSB Report - ${this.job.name} - ${
        localStorage.getItem("selectedaccountIds") || this.accountId
      }`,
    };

    Jhxlsx.export([dict], options);
  }

  async loadCheckDetailed(check: any) {
    check["loading"] = true;
    let data: any = {
      action: "list_check_rules",
      job_id: this.job.jobId,
      check_id: check.check_id,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      check["pagination"] = {
        page: 1,
        per_page: 10,
      };
      check["findings"] = result.checks;
    }
    check["loading"] = false;
  }

  onTableDataChange(event: any, check: any) {
    check["pagination"]["page"] = event;
  }

  prevent(event: any) {
    event.stopPropagation();
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  filterChecks() {
    if (this.filter["checks"] == "ALL") {
      return this.checks;
    }
    return this.checks.filter((check: any) => {
      if (
        this.filter["checks"] == "Automatic" ||
        this.filter["checks"] == "Manual"
      ) {
        return check.type == this.filter["checks"];
      } else if (this.filter["checks"] == "Passed") {
        return check.passed > 0 && check.failed == 0;
      } else if (this.filter["checks"] == "Failed") {
        return check.failed > 0;
      } else {
        return true;
      }
    });
  }

  filterFindings(findings: any) {
    if (this.filter["findings"] == "ALL") {
      return findings;
    }
    return findings.filter((finding: any) => {
      if (this.filter["findings"] == "Passed") {
        return !(finding["reason"] || finding["fix"]);
      } else if (this.filter["findings"] == "Failed") {
        return finding["reason"] || finding["fix"];
      } else {
        return true;
      }
    });
  }

  changePerPage(check: any) {
    check["pagination"]["page"] = 1;
  }

  loadFailedGraphs() {
    var chartDom = document.getElementById("failedGraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      let temp: any = this.checks.find((check: any) => {
        return check["check_id"] == params["name"];
      });
      if (temp) {
        this.filter["checks"] = "ALL";
        this.loadCheckDetailed(temp);
        temp["expanded"] = true;
        setTimeout(() => {
          if (document.getElementById(`check_${params["name"]}`)) {
            document.getElementById(`check_${params["name"]}`).scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start",
            });
          }
        }, 100);
      }
    });

    let option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Failed",
          type: "pie",
          radius: "50%",
          data: this.checksAcc
            .filter((check: any) => {
              return check.failed > 0;
            })
            .map((check: any) => {
              return { name: check.check_id, value: check.failed };
            }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadGraph() {
    var chartDom = document.getElementById("graphMain");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["checks"] = params["name"];
    });

    let option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          data: [
            {
              value: this.checks.filter((check: any) => {
                return check.type == "Automatic";
              }).length,
              name: "Automatic",
            },
            {
              value: this.checks.filter((check: any) => {
                return check.type == "Manual";
              }).length,
              name: "Manual",
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadTestScoreGraph(perc: any, title: string = "SCORE") {
    var chartDom = document.getElementById("testscore_graph");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
        showInTooltip: false,
      },
      series: [
        {
          name: "",
          type: "gauge",
          textStyle: {
            fontSize: 10,
          },
          progress: {
            show: true,
          },
          detail: {
            valueAnimation: true,
            formatter: "{value}",
          },
          data: [
            {
              value: Number(perc),
              name: title,
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  }

  // Delete Job
  async deleteJob(job: any) {
    this.loading["delete"] = true;
    let data = {
      action: "delete_job",
      account_id: this.accountId,
      region_id: this.regionId,
      job_id: this.job["job_id"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.hideModel(true);
    } else {
      this.notifier.alert("Info", "", result["error_message"], "info", 5000);
    }

    this.loading["delete"] = false;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
